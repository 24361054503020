import cn from 'classnames';
import { Form } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const SignatureComment = ({ ticketId }) => {
  const { Components } = Form.useFormContext();

  console.log('Components', Components);

  return (
    <Styled className={cn('signature-comment')}>
      <Components.CustomerComment />

      <Components.Signature edit ticketId={ticketId} />
    </Styled>
  );
};
