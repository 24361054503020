import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from '../style';
import { Form } from 'connex-cds';

const { useFormContext } = Form;

const Styled = styled.div`
  ${style}
`;

const UnloadingMethodForm = () => {
  const {
    Components: { UnloadingMethod },
  } = useFormContext();

  return (
    <Styled className={cn('the-form')}>
      <UnloadingMethod />
    </Styled>
  );
};

export default UnloadingMethodForm;
