import { css } from 'styled-components';

export default css`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  
  .signature-actions {
    
  }
`;
