import React from 'react';
import { Checkbox as CoreCheckbox } from '../../core';
import { useFormContext } from '../context-provider/FormProvider';

// inferFromListValue: {id: 'customer'}

export const Checkbox = props => {
  const { getValues } = useFormContext();

  const key = React.useMemo(() => {
    return Object.keys(props?.inferFromListValue)?.[0];
  }, [props?.inferFromListValue]);

  console.log('props', props);
  const { options, onChange, inferFromListValue } = props;
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    console.log('ue', options);
  }, [options]);

  React.useEffect(() => {
    if (props.value) {
      const inferFrom = inferFromListValue[key];
      setChecked(inferFrom === props.value);
    }
  }, [inferFromListValue, key, props.value]);

  const handleChange = React.useCallback(
    checked => {
      console.log('values', getValues());
      console.log('props', props);
      console.log('handleChange', checked);
      setChecked(checked);
    },
    [getValues, props]
  );

  return <CoreCheckbox onChange={handleChange} value={checked} />;
};
