import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import { Form, Core, Drawer } from 'connex-cds';

const Styled = styled.div`
  ${style}
`;

const { Button } = Core;
const { useDrawerContext } = Drawer;

const EditStatusTime = ({ statusType }) => {
  const {
    Components: { EndUnloading, ArriveJob },
  } = Form.useFormContext();

  const { closeDrawer } = useDrawerContext();

  const handleSave = React.useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  if (statusType === 'END_UNLOADING') {
    return (
      <Styled className={cn('loading-point')}>
        <EndUnloading />
        <Button type="primary" onClick={handleSave} stringId="save" data-testid="save-button" />
      </Styled>
    );
  } else if (statusType === 'ARRIVE_JOB') {
    return (
      <Styled className={cn('loading-point')}>
        <ArriveJob />
        <Button type="primary" onClick={handleSave} stringId="save" data-testid="save-button" />
      </Styled>
    );
  }
};

export default EditStatusTime;
