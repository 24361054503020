import { isEmpty, kebabCase } from 'lodash';
import React from 'react';
import { useGridContext } from '../../context/context';
import { Button } from '../../../button/Button';

const ClearFiltersButton = () => {
  const { filters, filterEnabled, clearFilters, gridName } = useGridContext();
  return !isEmpty(filters) && filterEnabled ? (
    <Button
      size="small"
      onClick={clearFilters}
      metricId={gridName ? `${kebabCase(gridName)}-clear-filters` : undefined}
      data-testid="clear-filters-button"
    >
      Clear Filters
    </Button>
  ) : null;
};

export default ClearFiltersButton;
