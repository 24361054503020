import { css } from 'styled-components';

export default css`
  &.ant-alert-info {
    background-color: var(--color-background-alert-info);
    .ant-alert-icon {
      color: var(--color-icon-alert-info);
    }
  }
  &.ant-alert-success {
    background-color: var(--color-background-alert-success);
    .ant-alert-icon {
      color: var(--color-icon-alert-success);
    }
  }

  &.ant-alert-warning {
    background-color: var(--color-background-alert-warning);
    .ant-alert-icon {
      color: var(--color-icon-alert-warning);
    }
  }

  &.ant-alert-error {
    background-color: var(--color-background-alert-error);
    .ant-alert-icon {
      color: var(--color-icon-alert-error);
    }
  }

  &.ant-alert {
    border: none;
    border-radius: var(--radius-alert);
    .ant-alert-icon {
      margin-top: auto;
      margin-bottom: auto;
      font-size: 24px;
    }
    .ant-alert-close-icon {
      margin-top: auto;
      margin-bottom: auto;
      font-size: 22px;
      margin-left: 20px;
    }
  }
`;
