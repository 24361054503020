import cn from 'classnames';
import { Typography } from 'connex-cds';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const Styled = styled.div``;
const Eula = () => {
  const location = useLocation();

  return (
    <Styled className={cn('eula')}>
      {location?.state?.from?.pathname && <Link to={location?.state?.from?.pathname}>Back</Link>}

      <Typography.Text>Eula</Typography.Text>
    </Styled>
  );
};

export default Eula;
