import React from 'react';
import { Button } from '../../core';
import { useFormContext } from './FormProvider';

export const ResetButton = ({ stringId, testId: testIdDeprecated, 'data-testid': dataTestId }) => {
  const { isValid, isSubmitting, dirty, resetForm } = useFormContext();

  const handleClick = React.useCallback(() => {
    resetForm();
  }, [resetForm]);

  const testId = React.useMemo(() => {
    return dataTestId || testIdDeprecated;
  }, [dataTestId, testIdDeprecated]);

  return (
    <Button
      size="large"
      stringId={stringId}
      onClick={handleClick}
      disabled={!isValid || !dirty || isSubmitting}
      data-testid={testId || 'reset-button'}
    />
  );
};
