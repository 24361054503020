import cn from 'classnames';
import { Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import style from './style';
import { TicketNumber } from './TicketNumber';
import { TimeStamp } from './TimeStamp';

const { Container } = Layout;

const Styled = styled(Container)`
  ${style}
`;

export const SignatureContainer = ({ ticketId }) => {
  const {
    Components: { Signature },
  } = Form.useFormContext();

  return (
    <Styled className={cn('signature-section')}>
      <TicketNumber />
      <Signature />
      <TimeStamp />
    </Styled>
  );
};
