import { Form, Core } from 'connex-cds';
import React from 'react';
import { useParams } from 'react-router-dom';
import config from '../../config';
import getFormProviderConfig from './getFormProviderConfig';
import { SetupView } from './SetupView';
import { useMasterData } from './useMasterData';

const { FormProvider } = Form;
const { Spinner } = Core;

export const DynamicSetup = () => {
  const { typeId } = useParams();
  const { masterData, getMasterData, busy } = useMasterData();

  React.useEffect(() => {
    getMasterData(typeId);
  }, [getMasterData, typeId]);

  const formConfig = React.useMemo(() => {
    return getFormProviderConfig(typeId, config[typeId]);
  }, [typeId]);

  return (
    <Spinner spin={!masterData || busy}>
      <FormProvider
        config={formConfig}
        initialValues={{
          [typeId]: masterData,
        }}
      >
        <SetupView typeId={typeId} config={config?.[typeId]} />
      </FormProvider>
    </Spinner>
  );
};
