import { css } from 'styled-components';

export default css`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  .app-header {
    height: 54px;
    display: flex;
    align-items: center;
    background-color: var(--color-background-header);
    color: var(--color-text-header);
    --color-text-page: var(--color-text-header);
  }
  .app-content {
    flex: 1;
    transition: all 0.3s;
    background-color: var(--color-background-page);
    color: var(--color-text);
  }
  .app-footer {
    height: 35px;
    background-color: var(--color-background-header);
    color: var(--color-text-footer);
    --color-text-page: var(--color-text-footer);
  }
`;
