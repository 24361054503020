import { Auth, Core, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../../assets/connex-logo-dark-theme.svg';
import style from './style';

const { useAuthContext } = Auth;
const { Button } = Core;
const { Column } = Layout;

const Styled = styled.div`
  ${style}
`;

const Header = props => {
  const { logout } = useAuthContext();
  const handleClick = React.useCallback(() => {
    logout();
  }, [logout]);

  return (
    <Styled>
      <div className="logo">
        <Logo height={25} />
      </div>
      <div className="right">
        <Button type="primary" onClick={handleClick} data-testid="logout-button">
          Logout
        </Button>
      </div>
    </Styled>
  );
};

export default Header;
