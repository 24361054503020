import cn from 'classnames';
import { Drawer, Form } from 'connex-cds';
import { camelCase, toUpper } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

export const GroupLayout = ({ config }) => {
  const { Components, values } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();

  const handleSave = React.useCallback(() => {
    console.log('saving', values);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('saved');
        closeDrawer();
        resolve();
      }, 2000);
    });
  }, [closeDrawer, values]);

  return (
    <Styled className={cn('setup-editor')}>
      <Components.Groups>
        {config.map(group => {
          const TabComponent = Components[pascalCase(group.groupId)];

          return (
            <TabComponent>
              {group.fields.map(field => {
                const FieldComponents = TabComponent.Components;
                const FieldComponent = FieldComponents[pascalCase(field.name || field.path)];
                return <FieldComponent />;
              })}
            </TabComponent>
          );
        })}
      </Components.Groups>
      <div className="actions">
        <div>
          <Components.DeleteButton onDelete={closeDrawer} />
        </div>
        <div>
          <Components.CancelButton onCancel={closeDrawer} enabled />
          <Components.SaveButton onSave={handleSave} suppressDisabledStyling />
        </div>
      </div>
    </Styled>
  );
};
