import { css } from 'styled-components';

export default css`
  &&& {
    width: 100%;
    transition: all 0.3s;

    &.busy {
      .ant-select-selector {
        position: relative;

        .ant-select-selection-search {
          position: absolute;
          top: 0;
          left: 14px;
          width: 14px;
          font-size: 14px;
          line-height: 35px;
          color: var(--color-text-page);
          font-family: 'Font Awesome 6 Pro';
          font-weight: 400;
          animation-name: fa-spin;
          animation-duration: var(--fa-animation-duration, 2s);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-timing-function: var(--fa-animation-timing, linear);
          animation-delay: var(--fa-animation-delay, 0);
          animation-direction: var(--fa-animation-direction, normal);
          //-webkit-font-smoothing: antialiased;
          //display: var(--fa-display, inline-block);
          //font-style: normal;
          //font-variant: normal;
          //line-height: 1;
          //text-rendering: auto;
          :before {
            content: '\f110';
          }

          input {
            display: none;
          }
        }
      }
    }

    &.ant-select-disabled {
      .ant-select-selector {
        color: var(--color-text-select-disabled);
        border-color: var(--color-border-select-disabled);
        background-color: var(--color-background-select-disabled)
      }

      .ant-select-arrow {
        color: var(--color-border-select-disabled);
      }
    }

    .ant-select-selector {
      transition: all 0.3s;
      height: 35px;
      border: 1px solid var(--color-border-select);
      border-radius: 4px;
      padding: 10px 16px;
      background-color: transparent;
      color: var(--color-text-page);

      .ant-select-selection-item {
        line-height: 12px;
        color: var(--color-text-select);
      }
    }

    .ant-select-arrow {
      transition: all 0.3s;
      color: var(--color-text-page);
      right: 20px;
    }
  }
`;
