import cn from 'classnames';
import { Core, Drawer, Form, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import DeliveryInformation from '../../delivery-information/DeliveryInformation';
import style from './style';

const { useDrawerContext } = Drawer;
const Styled = styled.div`
  ${style}
`;
export const Header = ({ ticket }) => {
  const { openDrawer } = useDrawerContext();

  const {
    Components: { SubmitButton },
  } = Form.useFormContext();

  const showDeliveryInformation = React.useCallback(() => {
    openDrawer({
      titleStringId: 'deliveryInformation',
      component: <DeliveryInformation data={ticket}></DeliveryInformation>,
    });
  }, [openDrawer, ticket]);

  return (
    <Styled className={cn('header')}>
      <div className="left-side">
        <Typography.Subtitle>
          <Localization.Translate stringId="customerAcceptance" data-testid="acceptance-header" />
        </Typography.Subtitle>
      </div>
      <div className="right-side">
        <Localization.LocaleSwitcher persist={false} />
        <Core.Button
          type="primary"
          size="small"
          stringId="deliveryInformation"
          onClick={showDeliveryInformation}
          data-testid="delivery-information-button"
        />
        <SubmitButton stringId="submitAcceptance" data-testid="submit-acceptance-button" size="small" />
      </div>
    </Styled>
  );
};
