import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  &&& {
    background-color: var(--color-background-dropdown);
    color: var(--color-text-dropdown);
    .ant-select-item {
      //background-color: var(--form-element-background-color);
      color: var(--color-text-dropdown);
      &.ant-select-item-option-active {
        background-color: var(--color-background-dropdown-hover);
        color: var(--color-text-dropdown-hover);
      }
      &.ant-select-item-option-selected {
        background-color: var(--color-background-dropdown-selected);
        color: var(--color-text-dropdown-selected);
      }
    }
  }
`;

const dropdownRenderer = menu => {
  return <Styled className={cn('dropdown')} >{menu}</Styled>;
};

export default dropdownRenderer;
