import React from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '../../../authenticated-layout/sidebar/Sidebar';

const Entity = props => {
  return (
    <Sidebar>
      <Outlet />
    </Sidebar>
  );
};

export default Entity;
