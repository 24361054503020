import React from 'react';
import { Chip, TYPES } from '../../core/chip/Chip';
import { useFormContext } from './FormProvider';

export const RequiredIndicator = ({ disabled }) => {
  const { isRequired } = useFormContext();

  if (!isRequired) return null;
  return <Chip stringId="required" type={TYPES.ERROR} disabled={disabled} />;
};
