import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div``;

const Privacy = props => {
  return <Styled className={cn('privacy')}>Privacy Policy</Styled>;
};

export default Privacy;
