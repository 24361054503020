import React from 'react';
import { mobileTicket } from '../../../api';

export const useCurrentTicket = () => {
  const [ticket, setTicket] = React.useState(null);
  const [busy, setBusy] = React.useState(false);

  const getCurrentTicket = React.useCallback(async () => {
    setBusy(true);
    const response = await mobileTicket.getCurrentTicket();
    setTicket(response);
    setBusy(false);
  }, []);

  return { ticket, busy, getCurrentTicket };
};
