import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { PaymentMethodEditor } from './PaymentMethodEditor';
import style from './style';
import { Form, Drawer, Localization, Typography } from 'connex-cds';

const { Translate } = Localization;
const { H3 } = Typography;

const Styled = styled.div`
  ${style}
`;
export const PaymentMethodList = () => {
  const { Components, resetMode } = Form.useFormContext();

  const { openDrawer } = Drawer.useDrawerContext();

  const handleClick = React.useCallback(() => {
    openDrawer({
      titleStringId: 'payment-method',
      component: <PaymentMethodEditor />,
      onClose: resetMode,
    });
  }, [openDrawer, resetMode]);

  return (
    <Styled className={cn('company-form')}>
      <div className="header">
        <div className="left">
          <H3>
            asdf
            <Translate stringId="payment-method" />
          </H3>
        </div>
        <div className="right">
          <Components.AddButton onClick={handleClick} size="small" stringId="add-payment-method" />
        </div>
      </div>
      <div className="list-section">
        <Components.ListComponent onClick={handleClick} gridName="connex-setup-payment-method" />
      </div>
    </Styled>
  );
};
