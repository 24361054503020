import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const Root = () => {
  const location = useLocation();

  const destination = React.useMemo(() => {
    // TODO: Determine where to send the user.  Perhaps the user's preferred starting location, their last known position, etc.

    if (location?.state?.from?.pathname) {
      return location.state.from.pathname;
    }

    const now = new Date().getMilliseconds();
    return `/connex/${now}`;
  }, [location]);

  return <Navigate to={destination} state={{}} replace />;
};

export default Root;
