import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import PreferencesDrawer from './preferences-drawer/PreferencesDrawer';
import { useGridContext } from '../../context/context';
import Preferences from './Preferences';

const Styled = styled.div``;

const PreferencesDrawerTrigger = () => {
  const { gridName, friendlyName } = useGridContext();
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = React.useCallback(() => {
    setIsOpen(s => !s);
  }, []);

  return (
    <Styled className={cn('preferences')}>
      <div className="preferences-icon" onClick={toggleDrawer}>
        <i className="far fa-ellipsis-v" title="Configure Layout" />
      </div>
      <PreferencesDrawer isOpen={isOpen} onClose={toggleDrawer} title={`${friendlyName || gridName} Preferences`}>
        <Preferences onDone={toggleDrawer} />
      </PreferencesDrawer>
    </Styled>
  );
};

export default PreferencesDrawerTrigger;
