import { css } from 'styled-components';

export default css`
  &&& {
    width: 100%;
    transition: all 0.3s;
    border-radius: 4px;
    ${({ $multiline }) => ($multiline ? '' : 'height: 35px')};
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;

    background-color: var(--color-background-input);
    color: var(--color-text-input);
    border-color: var(--color-border-input);

    &:focus {
      border-color: var(--color-border-input-focus);
    }

    &.ant-input-disabled {
      background-color: var(--color-background-input-disabled);
      color: var(--color-text-input-disabled);
      border-color: var(--color-border-input-disabled);
    }
  }
`;
