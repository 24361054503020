import { useCallback, useEffect, useState } from 'react';

export const useViewport = () => {
  const [dimensions, setDimensions] = useState({ height: window.innerHeight, width: window.innerWidth });

  const handleResize = useCallback(() => {
    setDimensions({ height: window.innerHeight, width: window.innerWidth });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return dimensions;
};
