import { css } from 'styled-components';

export default css`
  &&& {
    background-color: var(--color-background-dropdown);
    border: 1px solid var(--color-border-dropdown);
    box-shadow: var(--boxShadow-dropdown);
    
    min-width: 150px;

    .cx-menu-item {
      display: flex;
      align-items: center;

      .text-label {
        width: 100%;
      }

      .icon {
        margin-right: 1em;
        display: flex;
        color: var(--color-text);
      }

      &.text-item {
        margin-top: 0;
        margin-bottom: 0;
        background-color: var(--profile-dropdown-menu-header-background-color) !important;
        color: var(--baseTextColor);

        border-bottom: var(--profile-dropdown-menu-header-border-bottom);

        &.ant-menu-item-active {
          &:hover {
            background-color: inherit;
            cursor: default;
            color: var(--baseTextColor);
          }
        }

        &.ant-menu-item-selected {
          color: var(--baseTextColor);
        }
      }

      &:not(.active) {
        font-weight: normal; //var(--dropdown-menu-item-font-weight);
        background-color: var(--color-background-dropdown);

        .label {
          color: var(--color-text-dropdown);
          transition: all 0.3s;
        }

        &:hover {
          background-color: var(--color-background-dropdown-hover);

          .label {
            .cx-text {
              color: var(--color-text-dropdown-hover);
            }
          }
        }
      }

      &.active {
        font-weight: var(--fontWeight-dropdown-selected);
        background-color: var(--color-background-dropdown-selected);

        .label {
          //--primary-foreground-color: var(--dropdown-selected-text-color);
          color: var(--color-text-dropdown-selected);
          transition: color 0.3s;
        }
      }
    }
  }
`;
