import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { Auth } from 'connex-cds';

const { Authenticated } = Auth;

const AuthenticatedRoutes = () => {
  const location = useLocation();
  return (
    <Authenticated toLogin={<Navigate to="/login" state={{ from: location }} replace />}>
      <Outlet />
    </Authenticated>
  );
};

export default AuthenticatedRoutes;
