import { css } from 'styled-components';

export default css`
  padding: 0;
  .table-section {
    margin: 0 10px;
    table {
      width: 100%;
    }
    th {
      text-align: left;
    }
  }
`;
