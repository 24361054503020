import { Tabs } from 'antd';
import React from 'react';
import { useTranslateObjects } from '../../localization';
import { AddButton } from '../array/form/form-controls/AddButton';
import getFieldComponents from './getFieldComponents';
import getGroupComponents from './getGroupComponents';
import { ResetButton } from './ResetButton';
import { SubmitButton } from './SubmitButton';
import { RequiredIndicator } from './RequiredIndicator';

const Groups = ({ children }) => {
  // Since the antd Tabs component interrogates its children (TabPane) for a 'tab' prop
  // we have to jump through some hoops to automatically supply the correct props to the Groups' children.

  const kids = React.useMemo(() => {
    return React.Children.map(children, child => {
      // These values were stuck on each of the GroupComponents (i.e. TabPanes).
      // See getGroupComponents.
      const vals = { ...child.type };
      return React.cloneElement(child, { ...child.props, tab: vals?.__tab, key: vals?.__key });
    });
  }, [children]);

  return <Tabs>{kids}</Tabs>;
};

export const useComponents = ({ config, components, setFieldValue }) => {
  const { translateObjects } = useTranslateObjects();

  const Components = React.useMemo(() => {
    if (!config?.fields && !config?.groups) {
      return [];
    }

    if (config?.fields) {
      const fieldComponents = getFieldComponents({ config, setFieldValue });
      return { ...fieldComponents, RequiredIndicator, SubmitButton, AddButton, ResetButton, ...components };
    }

    if (config.groups) {
      const translatedGroups = translateObjects(config.groups, { getPath: 'groupNameStringId', setPath: 'groupName' });

      const groupComponents = getGroupComponents({ ...config, groups: translatedGroups }, setFieldValue);

      return { Groups, ...groupComponents, SubmitButton, AddButton, ResetButton, ...components };
    }
  }, [components, config, setFieldValue, translateObjects]);

  return { Components };
};
