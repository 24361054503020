import React from 'react';
import { useWatch } from 'react-hook-form';
import styled from 'styled-components';
import cn from 'classnames';
import { Checkbox as CoreCheckbox } from '../../core';
import { useFormContext } from '../context-provider/FormProvider';
import { Label } from '../label/Label';
import { ValidationText } from '../validation-text/ValidationText';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const Checkbox = React.forwardRef((_props, ref) => {
  const { formState, control } = useFormContext();
  const { fieldConfig, ...props } = _props;

  const watching = useWatch({ control, name: fieldConfig?.prerequisites });

  const prerequisitesMet = React.useMemo(() => {
    return !watching?.length || watching.every(item => !!item);
  }, [watching]);

  const disabled = React.useMemo(() => {
    return formState?.isSubmitting || !prerequisitesMet;
  }, [formState?.isSubmitting, prerequisitesMet]);

  return (
    <Styled className={cn('checkbox')}>
      <div className={cn('label-element', { horizontal: fieldConfig?.orientation === 'horizontal' })}>
        <Label stringId={fieldConfig.labelStringId} required={fieldConfig.required} disabled={disabled}>
          <CoreCheckbox testId={fieldConfig.testId} innerRef={ref} {...props} disabled={disabled} />
        </Label>
      </div>
      <ValidationText path={fieldConfig.path} errors={formState?.errors} />
    </Styled>
  );
});
