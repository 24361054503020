import { css } from 'styled-components';

export default css`
  .ant-drawer-content {
    background-color: var(--color-background-drawer);
    color: var(--color-text-drawer);
    .ant-drawer-header {
      background-color: var(--color-background-drawer);
      border-bottom: 1px solid var(--color-border-drawer-header);
      .ant-drawer-header-title {
        background-color: var(--color-background-drawer);
        color: var(--color-text-drawer);
        .ant-drawer-close {
          color: var(--color-icon-drawer-close);
        }
        .ant-drawer-title {
          color: var(--color-text-drawer);
        }
      }
    }
  }
`;
