import { Tabs as AntTabs } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useTranslateObjects } from '../../localization';
import style from './style';

const StyledTabs = styled(AntTabs)`
  ${style}
`;

const { TabPane } = AntTabs;

export const Tabs = ({ config }) => {
  const { translateObjects } = useTranslateObjects();

  const tabsConfig = React.useMemo(
    () => (config?.translate ? translateObjects(config.tabs, config.translate) : config.tabs),
    [config.tabs, config.translate, translateObjects]
  );

  return (
    <StyledTabs defaultActiveKey={0}>
      {tabsConfig?.map((tab) => (
        <TabPane tab={<span data-testid={tab.testId}>{tab.name}</span>} key={tab.testId} disabled={tab.disabled}>
          {tab.component}
        </TabPane>
      ))}
    </StyledTabs>
  );
};
