import { Layout } from 'connex-cds';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../unauthenticated/footer/Footer';
import Header from '../unauthenticated/header/Header';

const { HeaderFooter } = Layout;

const PublicLayout = props => {
  return (
    <HeaderFooter>
      <Header />
      <Outlet />
      <Footer />
    </HeaderFooter>
  );
};

export default PublicLayout;
