import { css } from 'styled-components';

export default css`
  .overview {
    padding: 0 20px;
    .ant-steps {
      .ant-steps-item {
        .ant-steps-item-title {
          color: var(--color-text-wizard);
          &:after {
            background-color: var(--color-background-wizard-title-line);
          }
        }

        &.ant-steps-item-finish {
          .ant-steps-item-container {
            .ant-steps-item-icon {
              background-color: var(--color-background-wizard-icon-finish);
            }
          }
        }

        &.ant-steps-item-wait {
          .ant-steps-item-container {
            .ant-steps-item-content {
              .ant-steps-item-title {
                color: var(--color-text-wizard-content-wait);
              }
            }

            .ant-steps-item-icon {
              background-color: var(--color-background-wizard-icon-wait);
              border-color: var(--color-border-wizard-icon-wait);
              .ant-steps-icon {
                color: var(--color-text-wizard-icon-wait);
              }
            }
          }
        }
      }
    }
  }

  .step-content {
    padding: 20px;
  }
`;
