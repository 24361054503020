import { css } from 'styled-components';

export default css`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  background-color: var(--color-background-header);
  padding-left: 20px;
  .logo {

  }

  .right {
    flex: 1;
    text-align: right;
  }
`;
