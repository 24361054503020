import cn from 'classnames';
import { Core, Drawer, Form, Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../../unauthenticated/footer/Footer';
import { Acceptance } from './acceptance/Acceptance';
import { Confirmation } from './Confirmation';
import DeliveryInformation from './delivery-information/DeliveryInformation';
import { DriverAcceptance } from './DriverAcceptance';
import FormSubmit from './FormSubmit';
import getFormConfig from './get-form-config';
import style from './style';
import TestCommentForm from './ticket-details/comments/TestCommentForm';
import LoadingPoint from './ticket-details/logistical-info/LoadingPoint';
import Material from './ticket-details/logistical-info/Material';
import StatusTimes from './ticket-details/logistical-info/StatusTimes';
import UnloadingPoint from './ticket-details/logistical-info/UnloadingPoint';
import ProductList from './ticket-details/products-and-services/ProductList';
import UnloadingMethodForm from './UnloadingMethodForm';
import { useCurrentTicket } from './useCurrentTicket';
import { Wizard } from './wizard/Wizard';

const { FormProvider, FIELD_TYPES, useFormContext } = Form;
const { Column, Row, Container } = Layout;
const { Button, Spinner } = Core;
const { DrawerProvider, useDrawerContext } = Drawer;
const { Translate } = Localization;
const { Subtitle } = Typography;

const Styled = styled(Column)`
  ${style}
`;

const MobileTicket = ({ ticket }) => {
  const { openDrawer, closeDrawer } = useDrawerContext();
  const {
    validateForm,
    Components: { DriverComment },
  } = useFormContext();

  React.useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleDeliveryInfoClick = React.useCallback(() => {
    openDrawer({
      titleStringId: 'deliveryInformation',
      component: <DeliveryInformation data={ticket}></DeliveryInformation>,
    });
  }, [ticket, openDrawer]);

  const onAbort = React.useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const navigate = useNavigate();
  const onComplete = React.useCallback(() => {
    closeDrawer();
    navigate('acceptance');
  }, [closeDrawer, navigate]);

  const startWizard = React.useCallback(() => {
    openDrawer({
      titleStringId: 'additionalInfo',
      component: <Wizard onComplete={onComplete} onAbort={onAbort} data={ticket} />,
      width: '100%',
      closable: false,
    });
  }, [onAbort, onComplete, openDrawer, ticket]);

  return (
    <Styled className={cn('layout')}>
      <Column>
        <div className={cn('header')}>
          <div className="left-side">
            <Subtitle>
              <Translate stringId="ticket" data-testid="ticketDetailsHeader" /> #{ticket?.id}{' '}
              <Translate stringId="details" data-testid="ticketDetailsHeader" />
            </Subtitle>
          </div>
          <div className="right-side">
            <Button
              size="small"
              type="primary"
              onClick={handleDeliveryInfoClick}
              stringId="deliveryInformation"
              data-testid="delivery-information-button"
            />
          </div>
        </div>
        <Row flex={1} style={{ padding: '10px' }}>
          <Column>
            <Row flex={1}>
              <Column flex={1}>
                <div style={{ height: '80px' }}>
                  <Container flex={1} style={{ padding: '5px' }}>
                    <Material data={ticket} />
                  </Container>
                </div>
                <LoadingPoint data={ticket} />
                <UnloadingPoint data={ticket} />
                <Container style={{ padding: '15px' }}>
                  <DriverComment rows={6} />
                </Container>
              </Column>
            </Row>
          </Column>
          <Column>
            <Row flex={1}>
              <Column flex={1}>
                <StatusTimes />
                <ProductList />
                <Container>
                  <UnloadingMethodForm />
                </Container>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row style={{ justifyContent: 'right', padding: '10px' }}>
          <Button type="primary" stringId="next" data-testid="next-button" onClick={startWizard} />
        </Row>
        <Footer />
      </Column>
    </Styled>
  );
};

const formConfig = getFormConfig([{ id: 'water', dataType: FIELD_TYPES.array, validation: { min: [1, 'required'] } }]);

export default () => {
  const { ticket, busy, getCurrentTicket } = useCurrentTicket();

  React.useEffect(() => {
    getCurrentTicket();
  }, [getCurrentTicket]);

  return (
    <Spinner spin={busy || !ticket}>
      <FormProvider config={formConfig} initialValues={ticket}>
        <DrawerProvider>
          <FormSubmit>
            <Routes>
              <Route path="" element={<MobileTicket ticket={ticket} />} />
              <Route path="acceptance" element={<Acceptance ticket={ticket} />} />
              <Route path="confirmation" element={<Confirmation />} />
            </Routes>
          </FormSubmit>
        </DrawerProvider>
      </FormProvider>
    </Spinner>
  );
};
