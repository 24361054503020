import React from 'react';
import { useLocalizationContext } from '../LocalizationProvider';
import toDateTime from '../util/toDateTime';

export const useTimeZone = ({ date, fullName, offset, namedOffset, local }) => {
  const { timeZone } = useLocalizationContext();

  const dateTime = React.useMemo(() => toDateTime(date, timeZone, local), [date, timeZone]);

  const tz = React.useMemo(() => {
    if (!dateTime) return null;

    if (offset) return dateTime.toFormat('ZZZ');

    return fullName ? timeZone : dateTime.toFormat('ZZZZ');
  }, [dateTime, fullName, offset, timeZone]);

  if (!date) return null;

  if ((offset || namedOffset) && !date)
    throw new Error(`Date is required. ${date}, ${offset}, ${namedOffset} ${fullName}`);

  return tz;
};

export const TimeZone = props => {
  const tz = useTimeZone(props);

  return <span data-testid="time-zone">{tz}</span>;
};
