import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Form, Layout, Localization, Core } from 'connex-cds';
import style from './style';
import { find } from 'lodash';

const { Column, Row, Container } = Layout;
const { Translate, Uom } = Localization;
const { Button } = Core;

const Styled = styled.div`
  ${style}
`;

export const ReturnedMaterials = ({ data }) => {
  const {
    Components: { ReturnReason, ReturnConcreteOnTruck, ReturnConcreteTime },
  } = Form.useFormContext();

  const mixInfo = React.useMemo(() => {
    const primaryLineItem = find(data?.lineItems, { isPrimary: true });

    return {
      mix: primaryLineItem?.item?.id + ' / ' + primaryLineItem?.item?.description,
      orderedQty: <Uom uom={primaryLineItem?.orderedQuantity} />,
    };
  }, [data]);

  return (
    <Styled className={cn('water')}>
      <Column>
        <Row>
          <Container flex={1}>
            <Column>
              <div className="body">
                <Translate stringId="mixIdLabel" data-testid="mixIdDescriptionLabel" /> :{' '}
                <span data-testid="mixIdDescriptionValue"> {mixInfo.mix} </span>
              </div>
              <div className="body">
                <Translate stringId="orderedQtyLabel" data-testid="orderedQtyLabel" /> :{' '}
                <span data-testid="orderedQtyValue"> {mixInfo.orderedQty} </span>
              </div>
              <div className="body">
                <Translate stringId="leftover" data-testid="leftoverLabel" /> : <span data-testid="leftoverQty"> </span>
              </div>
            </Column>
          </Container>
        </Row>
        <Row>
          <Column>
            <ReturnConcreteOnTruck />
          </Column>
        </Row>
        <Row>
          <Column>
            <ReturnReason
              options={[
                { id: '1', label: 'Site Access' },
                { id: '2', label: 'Cancelled Delivery' },
                { id: '3', label: 'Over Ordered' },
                { id: '4', label: 'Wrong Material' },
              ]}
            />
          </Column>
        </Row>
        <Row flex={1}>
          <Column flex={1}>
            <ReturnConcreteTime />
          </Column>
        </Row>
      </Column>
    </Styled>
  );
};
