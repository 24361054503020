const { REACT_APP_ENDPOINT = 'https://api-eu-west-1.commandalkon.io' } = process.env;

console.log(`Using API ${REACT_APP_ENDPOINT}`);

const hostname = (path = '') => {
  const { hostname } = window.location;

  return REACT_APP_ENDPOINT;
};

export default hostname;
