import { find, get, omit, isUndefined } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Select as CoreSelect } from '../../core';
import { useFormContext } from '../context-provider/FormProvider';
import { Label } from '../label/Label';
import { ValidationText } from '../validation-text/ValidationText';
import { Checkbox } from './Checkbox';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const Select = React.forwardRef((_props, ref) => {
  const formContext = useFormContext();
  const { disabled: formDisabled, isSubmitting, setFieldValue, values } = formContext;

  const { fieldConfig, options, isRequired, ...props } = _props;
  const dataTestId = { 'data-testid': `${fieldConfig.testId}-form-field` };

  const prerequisitesMet = React.useMemo(() => {
    if (fieldConfig?.prerequisites) {
      return fieldConfig.prerequisites.every(item => !!values[item]);
    }
    return true;
  }, [fieldConfig.prerequisites, values]);

  const [busy, setBusy] = React.useState(false);
  const [_options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (options instanceof Promise) {
      setBusy(true);
      options.then(setOptions).finally(() => {
        setBusy(false);
      });
    } else {
      setOptions(options || fieldConfig?.listValues || []);
    }
  }, [fieldConfig?.listValues, options]);

  const disabled = React.useMemo(() => {
    return isSubmitting || busy || !prerequisitesMet;
  }, [busy, isSubmitting, prerequisitesMet]);

  const mappedOptions = React.useMemo(() => {
    if (_options) {
      return _options.map(option => {
        return {
          id: option[fieldConfig?.listValuePath || 'id'],
          label: option[fieldConfig.listDisplayPath || 'label'],
        };
      });
    }
  }, [_options, fieldConfig]);

  const handleChange = React.useCallback(
    value => {
      const selectedOption = find(_options, { [fieldConfig?.listValuePath]: value });

      const transformedSelection = fieldConfig?.valuePath
        ? {
            ...omit(selectedOption, fieldConfig?.listValuePath),
            [fieldConfig?.valuePath]: value,
          }
        : value;

      const customValue = fieldConfig?.onChange?.(transformedSelection);

      setFieldValue(fieldConfig.path, customValue || transformedSelection);
    },

    [_options, fieldConfig, setFieldValue]
  );

  const value = React.useMemo(() => {
    // The CoreSelect component expects a primitive value.
    const path = fieldConfig.path
      .split('.')
      .concat([fieldConfig.valuePath])
      .filter(x => !isUndefined(x));

    return get(values, path);
  }, [fieldConfig.path, fieldConfig.valuePath, values]);

  const Component = React.useMemo(() => {
    return fieldConfig.inferFromListValue ? Checkbox : CoreSelect;
  }, [fieldConfig.inferFromListValue]);

  return (
    <Styled {...dataTestId}>
      <Label
        stringId={fieldConfig.fieldLabelStringId || fieldConfig.labelStringId}
        isRequired={isRequired}
        disabled={formDisabled || disabled}
        testId={fieldConfig.testId}
      />

      <Component
        innerRef={ref}
        {...props}
        onChange={handleChange}
        value={value}
        disabled={formDisabled || disabled}
        options={mappedOptions}
        busy={busy}
        testId={fieldConfig.testId}
        inferFromListValue={{ [fieldConfig.listValuePath || 'id']: fieldConfig.inferFromListValue }}
      />

      <ValidationText path={fieldConfig.path} name={fieldConfig.name} />
    </Styled>
  );
});
