import cn from 'classnames';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Core } from 'connex-cds';
import { DateTime } from 'luxon';

const Styled = styled.div`
  @media (max-width: 749px) {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'eula privacy'
      'copyright copyright';
  }

  @media (min-width: 750px) {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: 'eula copyright privacy';
  }
  margin: 0 20px;
  .eula {
    text-align: left;
  }
  .copyright {
    text-align: center;
  }
  .privacy {
    text-align: right;
  }
`;

const CURRENT_YEAR = DateTime.now().toFormat('yyyy');

export const LinkSection = props => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavClick = React.useCallback(
    to => {
      navigate(to);
    },
    [navigate]
  );

  return (
    <Styled className={cn('link-section')}>
      <Core.Link
        style={{ gridArea: 'eula' }}
        onClick={handleNavClick}
        to={'/eula'}
        testId="eula-link"
        stringId="eula"
        className="eula"
        state={{ from: location }}
      />
      <Core.Link
        style={{ gridArea: 'copyright' }}
        onClick={handleNavClick}
        to={'https://commandalkon.com'}
        testId="cai-link"
        stringId={{ id: 'copyright', values: { year: CURRENT_YEAR } }}
        className="copyright"
      />
      <Core.Link
        style={{ gridArea: 'privacy' }}
        onClick={handleNavClick}
        to={'/privacy-policy'}
        testId="privacy-policy-link"
        stringId="privacyPolicy"
        className="privacy"
      />
    </Styled>
  );
};
