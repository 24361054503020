import { Form } from 'connex-cds';

const { FIELD_TYPES } = Form;

export default (typeId, setupConfig) => {
  return {
    fields: [
      {
        path: typeId,
        labelStringId: typeId,
        icon: setupConfig?.icon,
        testId: typeId,
        dataType: FIELD_TYPES.array,
        keyPath: 'crn',
        arrayOf: {
          validationSchema: setupConfig?.validationSchema,
          fields: setupConfig?.fields,
          groups: setupConfig?.groups,
        },
      },
    ],
  };
};
