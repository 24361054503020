import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { GroupLayout } from './GroupLayout';
import { SimpleLayout } from './SimpleLayout';
import style from './style';

const Styled = styled.div`
  ${style}
`;
export const SetupEditor = ({ config }) => {
  if (config.groups) {
    return <GroupLayout config={config.groups} />;
  } else if (config.fields) {
    return <SimpleLayout config={config.fields} />;
  }

  return <Styled className={cn('setup-editor')}>Invalid Configuration</Styled>;
};
