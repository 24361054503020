import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { find } from 'lodash';
import style from './style';
import { Core, Localization, Typography } from 'connex-cds';

const { ShortDateTime, Translate } = Localization;
const { AccordionCard } = Core;
const { Subtitle } = Typography;

const Styled = styled.div`
  ${style}
`;

const UnloadingPoint = ({ data }) => {
  const deliveryAddress = React.useMemo(() => {
    const address = data?.destination?.address;
    return `${address?.address1} ${address?.city}, ${address?.countrySubDivision} ${address?.postalCode}`;
  }, [data]);

  const phoneNumber = React.useMemo(() => {
    const contact = find(data?.destination?.contacts, { contactRole: 'general' });
    return contact?.phone || '';
  }, [data?.destination?.contacts]);

  return (
    <Styled className={cn('unloading-point')}>
      <AccordionCard>
        <div>
          <Subtitle>
            <Translate stringId="unloadingPoint" data-testid="unloadingPointLabel" />
          </Subtitle>
          <div data-testid="deliveryAddressValue">{deliveryAddress}</div>
        </div>
        <div>
          <div>
            <Translate stringId="customer" data-testid="customerLabel" /> :{' '}
            <span data-testid="customerName">
              {data?.customerParty.id}/{data?.customerParty.name}
            </span>
          </div>
          <div>
            <Translate stringId="phoneNumber" data-testid="customerPhoneNumberLabel" /> :{' '}
            <span data-testid="customerPhoneNumber"> {phoneNumber} </span>
          </div>
          <div>
            <Translate stringId="unloadingScheduleStart" data-testid="unloadingScheduleStartLabel" />{' '}
            <span style={{ whiteSpace: 'nowrap' }} data-testid="unloadingScheduleStart">
              <ShortDateTime date={data?.scheduledEvents.UNLOADING} />
            </span>
          </div>
        </div>
      </AccordionCard>
    </Styled>
  );
};

export default UnloadingPoint;
