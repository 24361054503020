export default {
  'payment-method': [
    {
      pko: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      createDate: '2021-10-08T22:05:27Z',
      typeId: 'payment-method',
      status: 'ACTIVE',
      modifyDate: '2021-10-08T22:05:27Z',
      primaryKey: '0',
      entityRef: '498cf2f3-e696-4bee-bfa7-512667740b19',
      id: '0',
      pk: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      name: '0',
      crn: '1bc0d248-9261-58cc-b5f9-cfbd2ee139f4',
    },
    {
      pko: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      createDate: '2021-11-01T21:19:43Z',
      typeId: 'payment-method',
      status: 'ACTIVE',
      modifyDate: '2021-11-01T21:19:43Z',
      primaryKey: '1',
      entityRef: '498cf2f3-e696-4bee-bfa7-512667740b19',
      id: '1',
      pk: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      name: '1',
      crn: 'afa50114-9ece-5345-bb86-fbde13ff5455',
    },
    {
      pko: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      createDate: '2022-05-26T21:13:12Z',
      typeId: 'payment-method',
      status: 'ACTIVE',
      modifyDate: '2022-05-26T21:13:12Z',
      primaryKey: '2',
      entityRef: '498cf2f3-e696-4bee-bfa7-512667740b19',
      id: '2',
      pk: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      name: '2',
      crn: '14c5544e-1ad5-5051-903c-9ae8d949feaf',
    },
    {
      pko: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      createDate: '2021-10-15T13:41:40Z',
      typeId: 'payment-method',
      status: 'INACTIVE',
      modifyDate: '2021-11-04T13:21:42Z',
      id: 'AMEX',
      pk: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      name: 'American Express',
      crn: '4e2f58ea-fa26-493a-bfe1-148c1de2f1e6',
    },
    {
      typeId: 'payment-method',
      status: 'ACTIVE',
      defaultValue: false,
      entityRef: '498cf2f3-e696-4bee-bfa7-512667740b19',
      name: 'CASH',
      pko: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      createDate: '2021-08-31T16:25:37Z',
      modifyDate: '2021-11-04T15:14:13Z',
      primaryKey: 'CASH',
      id: 'CASH',
      pk: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      crn: '74f09d4c-9ffe-51c2-99f0-e546fca637a3',
    },
    {
      typeId: 'payment-method',
      status: 'ACTIVE',
      defaultValue: false,
      entityRef: '498cf2f3-e696-4bee-bfa7-512667740b19',
      name: 'CHECK',
      pko: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      createDate: '2021-08-31T16:25:37Z',
      modifyDate: '2021-08-31T16:25:37Z',
      primaryKey: 'CHECK',
      id: 'CHECK',
      pk: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      crn: '63876658-30fc-5f78-bf59-55f37cb7d244',
    },
    {
      typeId: 'payment-method',
      status: 'ACTIVE',
      defaultValue: false,
      entityRef: '498cf2f3-e696-4bee-bfa7-512667740b19',
      name: 'COD - CASH ONLY',
      invoiceable: 'YES',
      pko: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      createDate: '2021-08-31T16:25:37Z',
      modifyDate: '2022-04-22T14:11:37Z',
      primaryKey: 'CASHONLY',
      id: 'CASHONLY',
      pk: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      crn: '3dab01e1-a92d-5740-9c4f-9fbc35f2226e',
    },
    {
      typeId: 'payment-method',
      status: 'ACTIVE',
      defaultValue: false,
      entityRef: '498cf2f3-e696-4bee-bfa7-512667740b19',
      name: 'CREDIT CARD',
      pko: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      createDate: '2021-08-31T16:25:37Z',
      modifyDate: '2021-08-31T16:25:37Z',
      primaryKey: 'CREDIT CARD',
      id: 'CREDIT CARD',
      pk: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      crn: 'a02a42c2-53ad-5ee6-a72f-d8e63edc1c2f',
    },
    {
      typeId: 'payment-method',
      status: 'ACTIVE',
      defaultValue: true,
      entityRef: '498cf2f3-e696-4bee-bfa7-512667740b19',
      name: 'OPEN ACCOUNT',
      invoiceable: 'YES',
      pko: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      createDate: '2021-08-31T16:25:37Z',
      modifyDate: '2021-11-04T13:21:51Z',
      primaryKey: 'ACCOUNT',
      id: 'ACCOUNT',
      pk: '498cf2f3-e696-4bee-bfa7-512667740b19-payment-method',
      crn: '239c21a4-5917-5ac1-b7d6-3b05384e35a4',
    },
  ],
};
