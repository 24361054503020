import { http } from 'connex-cds';

export { default as auth } from './auth';
export { default as masterData } from './master-data';
export { default as mobileTicket } from './mobile-ticket';

export const API = {
  auth: http('auth'),
  mobileTicket: http('mobile-ticket'),
  masterData: http('master-data'),
};
