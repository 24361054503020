import React, { useContext } from 'react';
import { getTheme } from '../../design-system/getTheme';
import { globalTokens } from '../../design-system/global-tokens';
import { GlobalStyle } from './global-styles/GlobalStyle';

const ThemeContext = React.createContext();

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    console.trace();
    throw new Error(`useThemeContext cannot be rendered outside of the ThemeContext context provider`);
  }
  return context;
};

export const ThemeProvider = ({ children, themeName, themes = globalTokens }) => {
  const storedThemeName = localStorage.getItem('themeName');

  const [_themeName, _setThemeName] = React.useState(storedThemeName || themeName || 'dark');

  // Allows the theme to be change via the themeName prop. Useful in Storybook.
  React.useEffect(() => {
    if (themeName) {
      _setThemeName(themeName);
    }
  }, [themeName]);

  const theme = React.useMemo(() => {
    return getTheme(_themeName);
  }, [_themeName]);

  const setThemeName = React.useCallback((name, persist = true) => {
    if (persist) {
      localStorage.setItem('themeName', name);
    }
    _setThemeName(name);
  }, []);

  return (
    <ThemeContext.Provider value={{ themes, theme, themeName: _themeName, setThemeName }}>
      <GlobalStyle $theme={theme} />
      {children}
    </ThemeContext.Provider>
  );
};
