import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import { Core, Localization, Typography } from 'connex-cds';

const { ShortDateTime, Translate } = Localization;
const { AccordionCard } = Core;
const { Subtitle } = Typography;

const Styled = styled.div`
  ${style}
`;
const LoadingPoint = ({ data }) => {
  return (
    <Styled className={cn('loading-point')}>
      <AccordionCard>
        <div>
          <div>
            <Subtitle>
              <Translate stringId="loadingPoint" data-testid="loadingPointLabel" />
            </Subtitle>
          </div>
          <div data-testid="plantNameValue">{data?.origin?.description}</div>
        </div>
        <div style={{ paddingBottom: '10px' }}>
          <div data-testid="plantAddressValue">{data?.origin?.address.address1}</div>
          <div>
            <Translate stringId="loadingScheduleStart" data-testid="loadingScheduleStartLabel" />{' '}
            <span style={{ whiteSpace: 'nowrap' }} data-testid="loadingScheduleStart">
              <ShortDateTime date={data?.scheduledEvents.LOADING_STARTED} withDayOfWeek />
            </span>
          </div>
        </div>
      </AccordionCard>
    </Styled>
  );
};

export default LoadingPoint;
