import { Switch as AntSwitch } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Helper } from "../../typography";
import style from './style';

const StyledAntSwitch = styled(AntSwitch)`
  ${style}
`;

export const Switch = ({ innerRef, value, testId, checkedStringId, uncheckedStringId, onChange, ...props }) => {
  const dataAttributes = React.useMemo(() => {
    return { 'data-testid': testId, 'data-stringid': value ? checkedStringId : uncheckedStringId };
  }, [checkedStringId, testId, uncheckedStringId, value]);

  const handleClick = React.useCallback(
    checked => {
      onChange(checked);
    },
    [onChange]
  );

  return (
    <StyledAntSwitch
      checkedChildren={<Helper stringId={checkedStringId} />}
      unCheckedChildren={<Helper stringId={uncheckedStringId} />}
      className={`cds-switch`}
      onClick={handleClick}
      checked={value}
      {...props}
      {...dataAttributes}
    />
  );
};
