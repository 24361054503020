import { isEmpty, isNil } from 'lodash';

export default obj => {
  if (isEmpty(obj)) return;

  const notNullish = Object.keys(obj).filter(key => !isNil(obj[key]));

  if (notNullish?.length !== 1) {
    console.warn(`Mutually Exclusive: ${Object.keys(obj).join(', ')}`);
  }
};
