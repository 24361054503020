import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from '../../style';
import { Core, Localization, Drawer, Form } from 'connex-cds';
import { map, sortBy } from 'lodash';
import EditStatusTime from './EditStatusTime';

const { SimpleGrid } = Core;
const { Time, useTranslateObjects } = Localization;
const { useDrawerContext } = Drawer;

const Styled = styled.div`
  ${style}
`;

const columns = [
  { path: 'eventTypeTranslated', nameStringId: 'eventStatus' },
  { path: 'eventDateTime', nameStringId: 'time', cellRenderer: row => <Time date={row.eventDateTime} /> },
];

const StatusTimes = () => {
  const { values } = Form.useFormContext();

  const mockTicketEvents = map(values?.ticketEvents, (value, key) => ({ eventType: key, ...value }));
  const sortedEvents = sortBy(mockTicketEvents, ['eventDateTime']);
  const { translateObjects } = useTranslateObjects();
  const translatedTicketEvents = translateObjects(sortedEvents, {
    getPath: 'eventType',
    setPath: 'eventTypeTranslated',
  });

  const { openDrawer } = useDrawerContext();

  const handleRowEdit = React.useCallback(
    row => {
      openDrawer({ titleStringId: 'updateStatusTime', component: <EditStatusTime statusType={row?.eventType} /> });
    },
    [openDrawer]
  );

  const isRowEditable = React.useCallback(row => {
    return row?.eventType === 'ARRIVE_JOB' || row?.eventType === 'END_UNLOADING';
  }, []);

  return (
    <Styled className={cn('the-form')}>
      <SimpleGrid
        columns={columns}
        data={translatedTicketEvents}
        onRowEdit={handleRowEdit}
        isRowEditable={isRowEditable}
        data-testid="statusTimesGrid"
      />
    </Styled>
  );
};

export default StatusTimes;
