import { get, isUndefined } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Input as CoreInput, Select as CoreSelect } from '../../core';
import { useFormContext } from '../context-provider/FormProvider';
import { Label } from '../label/Label';
import { ValidationText } from '../validation-text/ValidationText';
import style from './style';

const Styled = styled.div`
  ${style}
`;
export const Quantity = React.forwardRef((props, ref) => {
  const { values, setFieldValue, disabled, isSubmitting, errors, handleBlur } = useFormContext();
  const { fieldConfig, isRequired } = props;
  const dataTestId = { 'data-testid': `${fieldConfig.testId}-form-field` };

  const path = React.useMemo(() => {
    return fieldConfig?.path
      .split('.')
      .concat([fieldConfig?.valuePath])
      .filter(x => !isUndefined(x));
  }, [fieldConfig?.path, fieldConfig?.valuePath]);

  const value = React.useMemo(() => {
    return get(values, path);
  }, [path, values]);

  const handleQuantityChange = React.useCallback(
    val => {
      const preparedValue = { ...value, value: val };

      const customValue = fieldConfig?.onChange?.(preparedValue);

      setFieldValue(fieldConfig.path, customValue || preparedValue);
    },
    [fieldConfig, setFieldValue, value]
  );

  const handleUomCodeChange = React.useCallback(
    val => {
      const preparedValue = { ...value, uomCode: val };
      const customValue = fieldConfig?.onChange?.(preparedValue);

      setFieldValue(fieldConfig.path, customValue || preparedValue);
    },
    [fieldConfig, setFieldValue, value]
  );

  const options = React.useMemo(() => {
    return [
      { id: 'LTR', label: 'Liter' },
      { id: 'MTQ', label: 'm\u00B3' },
    ];
  }, []);

  return (
    <Styled {...dataTestId}>
      <Label
        stringId={fieldConfig.labelStringId}
        testId={`${fieldConfig.testId}`}
        isRequired={isRequired && fieldConfig?.showRequiredIndicator !== false}
        disabled={disabled || isSubmitting}
      />
      <div className="controls">
        <CoreInput
          name={`${fieldConfig.path}.value`}
          onBlur={handleBlur}
          value={value?.value}
          onChange={handleQuantityChange}
          disabled={disabled || isSubmitting}
          type="text"
          testId={`${fieldConfig.testId}-value`}
        />
        <CoreSelect
          name={`${fieldConfig.path}.uomCode`}
          onBlur={handleBlur}
          onChange={handleUomCodeChange}
          value={value?.uomCode}
          disabled={disabled || isSubmitting}
          options={options}
          testId={`${fieldConfig.testId}-uom`}
        />
      </div>
      <ValidationText path={`${fieldConfig?.path}.value`} errors={errors} name={fieldConfig.name} />
    </Styled>
  );
});
