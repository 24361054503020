import React, { useRef } from 'react';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import cn from 'classnames';
import style from '../signature-comment/style';
import { Localization } from 'connex-cds';
import SignaturePad from 'react-signature-pad-wrapper';
const { Translate } = Localization;

const Styled = styled.div`
  ${style}
`;

export const SignatureCanvas = ({ signature, onChange, ticketId }) => {
  const signaturePadRef = useRef();
  const [haveSignature, setHaveSignature] = React.useState(false);

  const handleStrokeEnd = React.useCallback(() => {
    onChange({
      timestamp: DateTime.now().toUTC().toISO(),
      image: signaturePadRef?.current?.signaturePad?.toDataURL?.(),
      ticketId,
    });
  }, [onChange, ticketId]);

  const clearSignature = React.useCallback(() => {
    signaturePadRef.current.clear();
    setHaveSignature(false);
  }, []);

  const handleSave = React.useCallback(() => {
    const isEmpty = signaturePadRef.current.isEmpty();
    onChange({ image: isEmpty ? null : signaturePadRef.current.toDataURL() });
  }, [onChange]);

  React.useEffect(() => {
    if (signature?.image) {
      signaturePadRef.current.fromDataURL(signature.image);
      setHaveSignature(true);
    }
  }, [signature]);

  React.useEffect(() => {
    signaturePadRef?.current?.signaturePad?.addEventListener?.('endStroke', handleStrokeEnd);
    return () => signaturePadRef?.current?.signaturePad?.removeEventListener?.('endStroke', handleStrokeEnd);
  }, [handleStrokeEnd]);

  return (
    <Styled className={cn('signature')}>
      <div className="instruction">
        <Translate stringId="signatureInstruction" />
      </div>
      <div className="signature-outer">
        <div className="signature-inner">
          <SignaturePad redrawOnResize={true} ref={signaturePadRef} options={{ penColor: 'var(--color-text-page)' }} />
        </div>
      </div>
    </Styled>
  );
};
