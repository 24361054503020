import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import cn from 'classnames';
import TranslateString from '../../localization/util/translate-string-id/TranslateString';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

export const Chip = ({ type = TYPES.INFO, stringId, text, testId, values, disabled }) => {
  const dataAttributes = { 'data-stringid': stringId, 'data-testid': testId };
  return (
    <Styled className={cn('chip')} $type={type} $disabled={disabled} {...dataAttributes}>
      {stringId ? <TranslateString id={stringId} values={values} /> : text}
    </Styled>
  );
};
