import React, { useContext } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { isUndefined } from 'lodash';

const Styled = styled.div`
  transition: all 0.3s;
  padding: 25px;
  border-radius: var(--radius-container);
  color: var(--color-text-container);
  background-color: var(--color-background-container);
  border: 1px solid var(--color-border-container);
  box-shadow: var(--boxShadow-container);
  ${({ $flex }) => (isUndefined($flex) ? '' : `flex: ${$flex};`)}
  overflow: auto;

  display: flex;
  flex-direction: column;
  
  &.alt {
    background-color: var(--color-background-container-alt);
    border: 1px solid var(--color-border-container-alt);
    box-shadow: var(--boxShadow-container-alt);
  }
`;

const ContainerContext = React.createContext();

export const Container = ({ style, className, flex, children, alt }) => {
  const container = useContext(ContainerContext);
  return (
    <ContainerContext.Provider value={{ alt: !container?.alt }}>
      <Styled className={cn('container', { alt: container?.alt }, className)} style={style} $flex={flex}>
        {children}
      </Styled>
    </ContainerContext.Provider>
  );
};
