import { isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useGridContext } from '../../context/context';

const Styled = styled.div`
display:flex; 
grid-gap:10px;
`;

const Actions = ({ className }) => {
  const { actions, rows, sortColumns, filters, filterEnabled, visibleColumns, onFinish, refreshList } = useGridContext();

  return (
    <Styled className={cn('actions', className)}>
      {actions &&
        [].concat(actions)?.map?.(Component => {
          return (
            <Component
              rows={rows}
              columns={visibleColumns}
              sort={!isEmpty(sortColumns) ? sortColumns : undefined}
              filters={filterEnabled && !isEmpty(filters) ? filters : undefined}
              onFinish={onFinish}
              refreshList={refreshList}
            />
          );
        })}
    </Styled>
  );
};

export default Actions;
