import { css } from 'styled-components';

export default css`
  .ant-timeline {
    transition: all 0.3s;
    color: var(--color-text-page);
  }

  .ant-timeline-item-tail {
    transition: all 0.3s;
    border-left-color: var(--color-border-timeline-itemHead);
    border-left-width: 1px;
  }

  .ant-timeline-item-head {
    transition: all 0.3s;
    color: var(--color-foreground-timeline-itemHead);
    background-color: var(--color-background-timeline-itemHead);
    border-color: var(--color-border-timeline-itemHead);
  }
`;
