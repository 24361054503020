import { getLightTheme, getDarkTheme } from './alias-tokens';
import { globalTokens } from './global-tokens';

export const getTheme = themeName => {
  const themes = {
    light: () => getLightTheme(globalTokens.light),
    dark: () => getDarkTheme(globalTokens.dark),
  };

  return themes[themeName]?.();
};
