import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import pkg from '../package.json';
import { AuthContextProvider } from './components/auth';
import { DrawerProvider } from './components/drawer';
import { Localization } from './components/localization';
import { Column } from './components/layout';
import { ThemeProvider } from './components/theme';
import { getCachedItem } from './util/cache';

const Styled = styled(Column)`
  transition: all 0.3s;
  background-color: var(--color-background-page);
  color: var(--color-text-page);
  font-family: 'Noto Sans', serif;
  height: 100vh;
  width: 100%;
`;

export const ConnexDesignSystem = ({
  themes,
  onLogin,
  toLogin,
  children,
  className,
  messages,
  initialLocale,
  initialTimeZone,
  ...props
}) => {
  return (
    <Styled className={cn('cds-root-container', `v-${pkg.version}`, className)} {...props}>
      <Localization
        messages={messages}
        initialLocale={initialLocale || getCachedItem('language')}
        initialTimeZone={initialTimeZone || getCachedItem('timeZone')}
      >
        <ThemeProvider themes={themes}>
          <AuthContextProvider onLogin={onLogin} toLogin={toLogin}>
            <DrawerProvider>{children}</DrawerProvider>
          </AuthContextProvider>
        </ThemeProvider>
      </Localization>
    </Styled>
  );
};
