import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Entity from './entity-ref';
import { Setup } from './entity-ref/setup/Setup';
import { DynamicSetup } from './entity-ref/setup/type-id/dynamic-setup/DynamicSetup';
import { PaymentMethod } from './entity-ref/setup/type-id/payment-method/PaymentMethod';

const Connex = () => (
  <Routes>
    <Route path="/:entityRef" element={<Entity />}>
      <Route path="setup/*">
        <Route path="" element={<Setup />} exact />
        {/*<Route path="payment-method/*" element={<PaymentMethod />} />*/}
        <Route path=":typeId/*" element={<DynamicSetup />} />
      </Route>
    </Route>
  </Routes>
);

export default Connex;
