import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from '../../../style';
import { Form } from 'connex-cds';

const { useFormContext } = Form;

const Styled = styled.div`
  ${style}
`;

const CommentForm = () => {
  const {
    Components: { DriverComment },
  } = useFormContext();

  return (
    <Styled className={cn('the-form')}>
      <DriverComment rows={10} />
    </Styled>
  );
};

export default CommentForm;
