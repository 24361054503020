import { Form } from 'connex-cds';
import * as Yup from 'yup';
const { FIELD_TYPES } = Form;

export const FILTER_STRATEGY = {
  INCLUDES: 'includes',
  STARTS_WITH: 'startsWith',
  EQUALS: 'equals',
};

export default icon => ({
  icon,
  validationSchema: Yup.object().shape({
    id: Yup.string().required('required'),
    name: Yup.string().required('required'),
  }),
  fields: [
    { path: 'id', labelStringId: 'id', testId: 'id', dataType: FIELD_TYPES.string },
    { path: 'name', labelStringId: 'name', testId: 'name', dataType: FIELD_TYPES.string },
    {
      path: 'status',
      labelStringId: 'status',
      testId: 'status',
      dataType: FIELD_TYPES.string,
      listValues: [
        { id: 'ACTIVE', label: 'Active' },
        { id: 'INACTIVE', label: 'Inactive' },
      ],
      defaultValue: 'ACTIVE',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        );
      },
    },
  ],
});
