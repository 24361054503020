import { cloneDeep, get, set } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';

/**
 *
 * @param objects
 * @param paths
 * @param formatter
 * @returns {null|*[]}
 */
export const translateObjects = (objects, paths, formatter) => {
  const objectArray = [].concat(cloneDeep(objects));
  const pathsToTranslate = [].concat(paths);

  if (!objectArray?.length || !pathsToTranslate?.length) return null;
  return objectArray.map(object => {
    pathsToTranslate.forEach(path => {
      const getPath = path?.getPath || path;
      const setPath = path?.setPath || path;

      set(object, setPath, formatter({ id: get(object, getPath) }));
    });
    return object;
  });
};

export const useTranslateObjects = (objects, paths) => {
  const intl = useIntl();

  const translateObjects = React.useCallback(
    (objects, paths) => {
      if (!paths) return null;

      const objectArray = [].concat(cloneDeep(objects));
      const pathsToTranslate = [].concat(paths);

      if (!objectArray?.length || !pathsToTranslate?.length) return null;
      return objectArray.map(object => {
        pathsToTranslate.forEach(path => {
          const getPath = path?.getPath || path;
          const setPath = path?.setPath || path;

          set(object, setPath, intl.formatMessage({ id: get(object, getPath) }));
        });
        return object;
      });
    },
    [intl]
  );

  return { translateObjects };
};
