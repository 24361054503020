import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { AddProducts } from './steps/add-products-services/AddProducts';
import { ReturnedMaterials } from './steps/ReturnedMaterials';
import { AddWater } from './steps/add-water/AddWater';
import style from './style';
import { Core, Localization, Layout, Form } from 'connex-cds';

const Styled = styled.div`
  ${style}
`;

const { Button } = Core;

export const Wizard = ({ onAbort, onComplete, data, required }) => {
  const { errors } = Form.useFormContext();
  const [currentStep, setCurrentStep] = React.useState(0);
  const { translateObjects } = Localization.useTranslateObjects();

  const steps = React.useMemo(() => {
    return [
      { id: 'water', component: <AddWater required={required} /> },
      { id: 'materialService', component: <AddProducts required={required} /> },
      { id: 'returnedMaterial', component: <ReturnedMaterials required={required} data={data} /> },
    ];
  }, [data, required]);

  const translatedSteps = React.useMemo(() => {
    return translateObjects(steps, { getPath: 'id', setPath: 'title' });
  }, [steps, translateObjects]);

  const nextStep = React.useCallback(() => {
    if (currentStep === steps.length - 1) {
      onComplete?.();
      return;
    }
    setCurrentStep(s => s + 1);
  }, [currentStep, onComplete, steps.length]);

  const previousStep = React.useCallback(() => {
    if (currentStep === 0) {
      onAbort();
    } else {
      setCurrentStep(s => s - 1);
    }
  }, [currentStep, onAbort]);

  const nextDisabled = React.useMemo(() => {
    return errors[steps[currentStep].id];
  }, [currentStep, errors, steps]);

  return (
    <Styled className={cn('wizard')}>
      <Core.Steps steps={translatedSteps} currentStep={currentStep} />

      <div className="step-content">
        <div className="step-component">
          <Layout.Container alt={true}>{React.cloneElement(steps[currentStep].component, {})}</Layout.Container>
        </div>
        <div className="step-actions">
          <Button onClick={previousStep} stringId="back" data-testid="back-button" />
          <Button onClick={nextStep} stringId="next" data-testid="next-button" disabled={nextDisabled} />
        </div>
      </div>
    </Styled>
  );
};
