import { css } from 'styled-components';

export default css`
  color: var(--color-text-page);
  .ant-tabs-nav {
    &:before {
      border-color: var(--color-border-tabs);
    }
    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        color: var(--color-text-tabs);
      }

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: var(--color-text-tabs-active);
        }
      }
      &.ant-tabs-tab-disabled {
        .ant-tabs-tab-btn {
          color: var(--color-text-tabs-disabled);
        }
      }
    }
  }
`;
