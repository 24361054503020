import React from 'react';
import styled from 'styled-components';
import { isUndefined } from 'lodash';

const Styled = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  flex: ${({ $flex }) => (isUndefined($flex) ? '1' : $flex)};
  width: ${({ width }) => (isUndefined(width) ? '' : `${width}px`)};
`;

export const Column = ({ children, width, flex, ...props }) => {
  return (
    <Styled {...props} $flex={flex} $width={width}>
      {children}
    </Styled>
  );
};
