import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Dropdown as AntDropdown } from 'antd';
import style from './style';

const Styled = styled(AntDropdown)`
  ${style}
`;

export const Dropdown = ({ className, children, ...props }) => {
  return (
    <Styled className={cn('cx-dropdown', className)} {...props}>
      {children}
    </Styled>
  );
};

Dropdown.propTypes = {
  className: propTypes.string,
  children: propTypes.element,
};
