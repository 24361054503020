import { Drawer, Form } from 'connex-cds';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DriverAcceptance } from './DriverAcceptance';

const FormSubmit = ({ children }) => {
  const navigate = useNavigate();
  const { openDrawer } = Drawer.useDrawerContext();
  const { setOnSubmit } = Form.useFormContext();

  const saveTicket = React.useCallback(ticket => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }, []);

  const onSubmit = React.useCallback(
    values => {
      console.log('submitting', values);

      if (values?.signature?.image || values?.driverAcceptanceReason) {
        return saveTicket(values).then(() => {
          navigate('confirmation');
        });
      }

      openDrawer({
        titleStringId: 'driverAcceptance',
        component: <DriverAcceptance />,
      });
    },
    [navigate, openDrawer, saveTicket]
  );

  React.useEffect(() => {
    setOnSubmit(onSubmit);
  }, [onSubmit, setOnSubmit]);

  return children;
};

export default FormSubmit;
