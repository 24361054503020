import { css } from 'styled-components';

export default css`
  .comment-section {
    margin-top: 10px;
  }
  .comment-date-time {
    font-weight: bold;
    display: flex;
    & > *:first-child {
      flex: 1;
    }
  }
  .actions {
    margin: auto;
    margin-top: 10px;
  }
`;
