import React from 'react';
import styled from 'styled-components';
import style from './style';
import cn from 'classnames';

const Span = styled.span`
  ${style}
`;

const StyledH3 = styled.h3`
  ${style}
`;

const StyledH2 = styled.h2`
  ${style}
`;
const StyledH1 = styled.h1`
  ${style}
`;

export const H1 = ({ className, children, ...props }) => (
  <StyledH1 className={cn('h1', className)} {...props}>
    {children}
  </StyledH1>
);

export const H2 = ({ className, children, ...props }) => (
  <StyledH2 className={cn('h2', className)} {...props}>
    {children}
  </StyledH2>
);

export const H3 = ({ className, children, ...props }) => (
  <StyledH3 className={cn('h3', className)} {...props}>
    {children}
  </StyledH3>
);

export const Subtitle = ({ className, children, ...props }) => (
  <Span className={cn('subtitle', className)} {...props}>
    {children}
  </Span>
);

export const Body = ({ className, children, ...props }) => (
  <Span className={cn('body', className)} {...props}>
    {children}
  </Span>
);

export const Helper = ({ className, children, ...props }) => (
  <Span className={cn('helper', className)} {...props}>
    {children}
  </Span>
);
