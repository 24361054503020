import { DateTime } from 'luxon';
import { Form, Localization } from 'connex-cds';
import { Signature } from './signature-component/Signature';
import * as Yup from 'yup';

const { FIELD_TYPES } = Form;
const { Uom, Time } = Localization;

export default [
  {
    path: 'water',
    labelStringId: 'water',
    testId: 'water',
    dataType: FIELD_TYPES.array,
    arrayOf: {
      validationSchema: Yup.object().shape({
        quantity: Yup.object()
          .required('required')
          .shape({
            value: Yup.number().required('required').positive(),
            uomCode: Yup.string().required('required'),
          }),
      }),
      fields: [
        {
          path: 'addedBy',
          valuePath: 'id',
          displayPath: 'label',
          labelStringId: 'addedBy',
          dataType: FIELD_TYPES.string,
          listValues: [],
          listValuePath: 'id',
          listDisplayPath: 'label',
          testId: 'added-by',
        },
        {
          path: 'a.b.reason',
          name: 'reason',
          valuePath: 'reasonRef',
          displayPath: 'description',
          labelStringId: 'reason',
          dataType: FIELD_TYPES.string,
          listValues: [
            { crn: 'cd2aafd8-c251-4df0-8f9c-e2e13150a541', id: 'CD', description: 'Cement Dry' },
            { crn: 'crec73b-c542-496e-9ac1-b4d3536d8f60', id: 'PCR', description: 'Per Customer Request' },
          ],
          listValuePath: 'crn',
          listDisplayPath: 'description',
          testId: 'reason',
        },
        {
          path: 'quantity',
          labelStringId: 'quantity',
          dataType: FIELD_TYPES.quantity,
          uomListValues: ['LTR', 'YDQ'],
          cellRenderer: row => <Uom uom={row.quantity} />,
          required: { message: 'required' },
          testId: 'quantity',
        },
        {
          path: 'concreteOnTruck',
          labelStringId: 'concreteOnTruck',
          dataType: FIELD_TYPES.quantity,
          cellRenderer: row => <Uom uom={row.concreteOnTruck} />,
          testId: 'concreteOnTruck',
        },
        {
          path: 'time',
          labelStringId: 'time',
          dataType: FIELD_TYPES.time,
          cellRenderer: row => <Time date={row.time} />,
          testId: 'time',
        },
      ],
    },
  },
  {
    path: 'materialService',
    labelStringId: 'materialService',
    dataType: FIELD_TYPES.array,
    testId: 'materialService',
    keyPath: '_id',
    arrayOf: {
      fields: [
        {
          path: 'addedBy',
          valuePath: 'id',
          displayPath: 'label',
          labelStringId: 'addedBy',
          dataType: FIELD_TYPES.string,
          listValues: [],
          listValuePath: 'id',
          listDisplayPath: 'label',
          testId: 'added-by',
        },
        {
          path: 'product',
          name: 'product',
          valuePath: 'itemRef',
          displayPath: 'description',
          labelStringId: 'materialService',
          dataType: FIELD_TYPES.string,
          listValues: [
            { crn: 'cd2aafd8-c222-4df0-8f9c-e2e13150a541', id: '100', description: 'Plasticizer' },
            { crn: 'sql273b-c542-496e-9ac1-b4d3536d8f60', id: '102', description: 'Retarder' },
            { crn: 'cb2aafd8-c222-4df0-8f9c-e2e13150890', id: '900', description: 'Hose Length > 30m' },
            { crn: 'abc273b-c542-496e-1234-b4d3536d8f60', id: '909', description: 'Payment Method Cash' },
            { crn: 'cai273b-c999-496e-1234-b4d3536d8f60', id: '922', description: 'Service A' },
          ],
          listValuePath: 'crn',
          listDisplayPath: 'description',
          required: { message: 'required' },
          testId: 'product',
        },
        {
          path: 'quantity',
          labelStringId: 'quantity',
          dataType: FIELD_TYPES.quantity,
          uomListValues: ['LTR', 'YDQ'],
          cellRenderer: row => <Uom uom={row.quantity} />,
          testId: 'quantity',
        },
        {
          path: 'concreteOnTruck',
          labelStringId: 'concreteOnTruck',
          dataType: FIELD_TYPES.quantity,
          cellRenderer: row => <Uom uom={row.concreteOnTruck} />,
          testId: 'concreteOnTruck',
        },
        {
          path: 'time',
          labelStringId: 'time',
          dataType: FIELD_TYPES.time,
          cellRenderer: row => <Time date={row.time} />,
          testId: 'time',
        },
      ],
    },
  },
  {
    path: 'returnConcreteOnTruck',
    labelStringId: 'concreteOnTruck',
    dataType: FIELD_TYPES.quantity,
    testId: 'concreteOnTruck',
  },
  {
    path: 'returnReason',
    valuePath: 'id',
    displayPath: 'label',
    labelStringId: 'reason',
    dataType: FIELD_TYPES.string,
    listValues: [],
    listValuePath: 'id',
    listDisplayPath: 'label',
    testId: 'returnReason',
  },
  {
    path: 'returnConcreteTime',
    labelStringId: 'time',
    dataType: FIELD_TYPES.time,
    testId: 'time',
  },
  {
    path: 'signature',
    labelStringId: 'reason',
    Component: Signature,
  },
  {
    path: 'driverComment',
    valuePath: 'text',
    labelStringId: 'driverComment',
    dataType: FIELD_TYPES.string,
    onChange: comment => ({ timestamp: DateTime.now().toUTC().toISO(), text: comment }),
    testId: 'driverComment',
    multiline: true,
  },
  {
    path: 'customerComment',
    valuePath: 'text',
    labelStringId: 'customerComment',
    dataType: FIELD_TYPES.string,
    onChange: comment => ({ timestamp: DateTime.now().toUTC().toISO(), text: comment }),
    testId: 'customerComment',
    multiline: true,
  },
  {
    path: 'unloadingMethod',
    labelStringId: 'unloadingMethod',
    dataType: FIELD_TYPES.string,
    listValues: [
      { id: '1', label: 'Pump' },
      { id: '2', label: 'Wheelbarrow' },
      { id: '3', label: 'Front Discharge' },
      { id: '4', label: 'Rear Discharge' },
    ],
    initialFocus: true,
    testId: 'unloadingMethod',
  },
  {
    path: 'driverAcceptanceReason',
    labelStringId: 'driverAcceptanceReason',
    dataType: FIELD_TYPES.string,
    listValues: [
      { id: '1', label: 'Customer not present.' },
      { id: '2', label: 'Refused' },
    ],
    initialFocus: true,
    testId: 'driverAcceptanceReason',
  },
  {
    path: 'ticketEvents.END_UNLOADING.eventDateTime',
    name: 'endUnloading',
    labelStringId: 'END_UNLOADING',
    dataType: FIELD_TYPES.time,
    testId: 'time',
  },
  {
    path: 'ticketEvents.ARRIVE_JOB.eventDateTime',
    name: 'arriveJob',
    labelStringId: 'ARRIVE_JOB',
    dataType: FIELD_TYPES.time,
    testId: 'time',
  },
];
