import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from '../style';
import { Core } from 'connex-cds';
import GeneralDeliveryInfo from './GeneralDeliveryInfo';
import MaterialInfo from './MaterialInfo';
import QRCode from './QRCode';

const { Tabs } = Core;

const Styled = styled.div`
  ${style}
`;
const DeliveryInformation = ({ data }) => {
  const config = React.useMemo(() => {
    return {
      tabs: [
        {
          stringId: 'generalDelivery',
          testId: 'generalDeliveryLabel',
          component: <GeneralDeliveryInfo data={data}></GeneralDeliveryInfo>,
        },
        {
          stringId: 'materialInfo',
          testId: 'materialInfoLabel',
          component: <MaterialInfo data={data}></MaterialInfo>,
        },
        {
          stringId: 'qrCode',
          testId: 'qrCodeLabel',
          component: <QRCode></QRCode>,
        },
      ],
      translate: [{ getPath: 'stringId', setPath: 'name' }],
    };
  }, [data]);

  return (
    <Styled className={cn('test-form')}>
      <div>
        <Tabs config={config} />
      </div>
    </Styled>
  );
};

export default DeliveryInformation;
