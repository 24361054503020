import React from 'react';
import styled from 'styled-components';
import { Translate } from '../../localization';
import { Subtitle } from '../../typography';

const Styled = styled(Subtitle)`
  display: block;
  height: 22px;
`;

export const CardSubtitle = ({ stringId }) => {
  if (!stringId) return null;
  return (
    <Styled>
      <Translate stringId={stringId} />
    </Styled>
  );
};
