import queryString from 'query-string';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { isEmpty } from 'lodash';
import App from './App';
import { cacheItem } from './util/cache';

const queryParams = queryString.parse(window.location.search);

if (!isEmpty(queryParams)) {
  const previousConnexId = localStorage.getItem('x-connex-id') || '';

  const connexId = queryParams['token'] || previousConnexId;
  const source = queryParams['source'] || localStorage.getItem('source');
  const language = queryParams['language'] || localStorage.getItem('language');
  const themeName = queryParams['theme'] || localStorage.getItem('themeName') || 'dark';

  cacheItem('source', source || '');
  cacheItem('themeName', themeName);
  cacheItem('language', language || '');

  if (connexId) {
    cacheItem('x-connex-id', connexId);
  }

  // Strip query params off URL
  window.location.href = `${window?.location?.pathname?.[0] !== '/' ? '/' : ''}${window.location.pathname}`;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
