export default {
  _event_time: '2022-06-30T15:59:48.000000Z',
  _id: '90df8fea-1064-7b2e-8bea-cb67e33382b3',
  _meta: {
    dynamodb: {
      table: 'connex-stage-platform',
      shard_id: 'shardId-00000001656596075901-3a43bb7d',
      event_id: 'ac633f811fcbcd926594ace54286707c',
    },
  },
  ticketEvents: {
    END_UNLOADING: {
      longitude: -96.4474,
      latitude: 32.6738,
      eventDateTime: '2022-07-28T15:31:36Z',
    },
    IN_YARD: {
      longitude: -96.33992,
      latitude: 32.89928,
      eventDateTime: '2022-07-28T16:38:22Z',
    },
    TO_JOB: {
      longitude: -96.340256,
      latitude: 32.901936,
      eventDateTime: '2022-07-28T14:23:35Z',
    },
    LEAVE_JOB: {
      longitude: -96.448296,
      latitude: 32.674957,
      eventDateTime: '2022-07-28T15:51:36Z',
    },
    CREATED: {
      longitude: -96.47047,
      latitude: 32.74904,
      eventDateTime: '2022-07-28T14:11:31.292Z',
    },
    UNLOADING: {
      longitude: -96.44858,
      latitude: 32.672276,
      eventDateTime: '2022-07-28T15:13:06Z',
    },
    ARRIVE_JOB: {
      longitude: -96.44875,
      latitude: 32.672173,
      eventDateTime: '2022-07-28T14:56:36Z',
    },
  },
  pk: 'connex-51f55611-3e74-48df-9437-99815d818522-ticket',
  supplierSalesOrder: {
    originatorRecordId: 'a8ce0a6c-5220-5b97-80e9-9a5d79b6b648',
    id: 'My Customer',
    name: 'Segment Factory {Mobile Baustoffe}',
  },
  id: '123412341234',
  destination: {
    address: {
      city: 'Hoover',
      coordinates: {},
      address1: '515 Greenbrier Way',
      postalCode: '35244',
      countryCode: 'US',
      countrySubDivision: 'AL',
    },
    id: 'My Customer',
    description: 'Segment Factory {Mobile Baustoffe} Strabag AG UK Branch',
    contacts: [
      {
        phone: '07976 740389',
        userRef: null,
        contactRole: 'general',
        email: null,
        name: 'Gill Simpson',
      },
    ],
    deliveryZone: {
      description: null,
      id: null,
    },
    originatorRecordId: 'My Customer',
    deliveryBlock: null,
    deliveryLots: [],
    instructions: 'NEAREST CROSS RD: Wilton Site',
  },
  currencyCode: null,
  salesAnalysis: {
    description: null,
    id: null,
  },
  orderDiscipline: {
    id: null,
    description: null,
    disciplineRef: null,
  },
  entityRef: '51f55611-3e74-48df-9437-99815d818522',
  displayLabel: '123412341234',
  dispatchDateTime: '2022-07-07T16:09:16Z',
  pko: 'connex-51f55611-3e74-48df-9437-99815d818522-ticket',
  supplierStatus: {
    reasonCode: '09',
    statusCode: 'VOIDED',
    reason: "You're Fired",
    eventDateTime: '2022-07-08T16:25:29Z',
  },
  carrierParty: {
    originatorRecordId: 'Default Carrier',
    id: 'Default Carrier',
    contacts: [],
    name: 'Default Carrier',
    entityRef: 'de0e48b3-24ba-5a7f-ad30-5ed403d101cd',
  },
  modifyDate: '2022-07-08T16:25:29Z',
  connex: {
    mod: {
      type: 'C',
      modifyDate: '2022-07-07T16:09:25Z',
    },
    dataModelVersion: '2.0',
  },
  eTicketContacts: [],
  active: true,
  isVoided: true,
  lookupKey: '123412341234',
  jobArea: {
    id: null,
    description: null,
    jobAreaRef: null,
  },
  dispatchOrder: {
    totalTicketed: {
      value: 30,
      reasonCode: null,
      loadNumber: 1,
    },
    originatorRecordId: '123412341234',
    id: '123412341234',
    totalOrdered: 60,
    dispatchOrderRef: '22461204-c68e-5331-80dc-90f6c3765e84',
  },
  paymentMethod: 'UNKNOWN',
  inventoryLocation: null,
  invoiceFrequency: null,
  invoice: {
    invoiceDateTime: null,
    id: null,
  },
  customerStatus: {
    statusCode: 'PENDING',
  },
  createdBy: {
    name: 'HC',
    id: 'HC',
  },
  crn: '22461204-c68e-5331-80dc-90f6c3765e84',
  createDate: '2022-07-07T16:09:25.309Z',
  originatorSystemId: '123412341234',
  customerParty: {
    originatorRecordId: 'a8ce0a6c-5220-5b97-80e9-9a5d79b6b648',
    id: 'My Customer',
    name: 'My Customer',
    entityRef: 'a8ce0a6c-5220-5b97-80e9-9a5d79b6b648',
  },
  loadNumber: null,
  originatorSystemType: 'HC',
  purchaseOrder: '12345',
  primaryKey: '2022-07-07T16:09:16Z',
  deliveryOption: 'DELIVERY',
  typeId: 'ticket',
  driver: {
    userRef: null,
    originatorRecordId: null,
    id: null,
    lastName: null,
    firstName: null,
  },
  CONNEX: {
    SID: 'ticket',
    EID: '51f55611-3e74-48df-9437-99815d818522',
  },
  originatorModifiedDateTime: '2022-07-07T16:09:16Z',
  customData: {
    manualLoadTrigger: false,
    runSheetNumber: '45654654',
    ticketType: 'DLV',
  },
  scheduledEvents: {
    END_UNLOADING: '2022-07-11T15:15:00Z',
    LOADING_STARTED: '2022-07-11T14:15:00Z',
    IN_YARD: '2022-07-11T15:55:00Z',
    TO_JOB: '2022-07-11T14:30:00Z',
    LEAVE_JOB: '2022-07-11T15:25:00Z',
    PRINTED: '2022-07-11T14:10:00Z',
    UNLOADING: '2022-07-11T15:05:00Z',
    ARRIVE_JOB: '2022-07-11T15:00:00Z',
  },
  weighMaster: {
    originatorRecordId: null,
    id: null,
    lastName: null,
    firstName: null,
  },
  salesPerson: {
    originatorRecordId: null,
    userRef: null,
  },
  priceSummary: {
    taxRegion: null,
    taxAmount: 0,
    taxCode: null,
    subTotalPrice: 0,
    subtotalPrice: 0,
    pricingLocation: null,
    taxRate: null,
    totalPrice: 0,
    totalFreightCharge: 0,
  },
  eTicketStatus: null,
  orderRequest: {
    id: null,
    description: null,
    orderRequestRef: null,
  },
  commerceTypeCode: 'SELL',
  originatorCreatedDateTime: '2022-07-07T16:09:16Z',
  originatorClientId: '51f55611-3e74-48df-9437-99815d818522',
  origin: {
    address: {
      city: 'Middlesbrough',
      coordinates: {
        longitude: -1.247161,
        latitude: 54.580914,
      },
      address1: 'Forty Foot Road',
      postalCode: 'TS2 1HG GB',
      countryCode: 'GBR',
      countrySubDivision: null,
    },
    scale: {
      name: null,
      id: null,
    },
    originatorRecordId: 'GB07',
    id: 'GB07',
    description: 'Middlesbrough Depot',
  },
  dataModelVersion: '2.0.99',
  primaryItemType: 'CONCRETE',
  lineItems: [
    {
      customerOrderItem: {
        productCostCode: null,
        typeOfWork: null,
        haulCostCode: null,
        purchaseOrder: {
          number: null,
          line: null,
        },
      },
      item: {
        itemType: 'CONCRETE',
        id: '1',
        description: '1',
        itemCategoryId: null,
        pricingPlanId: null,
        itemSubType: null,
        originatorRecordId: '1',
        productRef: null,
      },
      extendedPrice: 0,
      reshippedTicket: {
        reshippedQuantity: {
          value: null,
          uomCode: null,
        },
        originatorRecordId: null,
        id: null,
        originatorSystemType: null,
        originatorSystemId: null,
      },
      returnedQuantity: {
        value: null,
        uomCode: null,
      },
      orderedQuantity: {
        value: 100,
        uomCode: 'TO',
      },
      soldQuantity: {
        value: null,
        uomCode: null,
      },
      batchQuantity: {
        value: null,
        uomCode: null,
      },
      isPrimary: true,
      dumpedQuantity: {
        value: null,
        uomCode: null,
      },
      unitPrice: 0,
      quantity: {
        value: 30,
        uomCode: 'TO',
      },
      customerItem: {
        description: null,
        id: null,
      },
    },
  ],
  originatorRecordId: '123412341234',
  supplierParty: {
    originatorRecordId: '51f55611-3e74-48df-9437-99815d818522',
    name: 'Heidelberg Cement Dev',
    entityRef: '51f55611-3e74-48df-9437-99815d818522',
  },
  containers: [
    {
      netWeight: {
        value: 30,
        uomCode: 'TO',
      },
      item: {
        description: '1',
        id: '1',
      },
      maxGrossWeight: {
        value: null,
        uomCode: null,
      },
      tareWeight: {
        value: 0,
        uomCode: 'TO',
      },
      grossWeight: {
        value: 0,
        uomCode: 'TO',
      },
      vehicle: {
        vehicleType: {
          description: 'gots to have a hemi',
          id: 'gots to have a hemi',
        },
        originatorRecordId: 'BEM1661',
        id: 'BEM1661',
        description: 'BEM1661',
      },
    },
  ],
  transportationTermsCode: null,
  order: {
    orderRef: '22461204-c68e-5331-80dc-90f6c3765e84',
    id: '123412341234',
  },
};
