import { DateTime } from 'luxon';

const toDateTime = (date, timeZone, local) => {
  if (!date) return null;

  let dt;

  if (date instanceof DateTime) {
    dt = date;
  } else {
    dt = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
  }

  if (local) {
    return dt.toLocal();
  }

  if (timeZone) {
    return dt.setZone(timeZone);
  }

  return dt;
};

export default toDateTime;
