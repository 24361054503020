import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { find } from 'lodash';
import style from './style';
import { Localization, Typography } from 'connex-cds';

const { Uom, Translate } = Localization;
const { Subtitle } = Typography;

const Styled = styled.div`
  ${style}
`;

const Material = ({ data }) => {
  const mixInfo = React.useMemo(() => {
    const primaryLineItem = find(data?.lineItems, { isPrimary: true });

    return {
      mix: primaryLineItem?.item?.id + ' / ' + primaryLineItem?.item?.description,
      orderedQty: <Uom uom={primaryLineItem?.orderedQuantity} />,
      loadedQty: <Uom uom={primaryLineItem?.quantity} />,
    };
  }, [data]);

  return (
    <Styled className={cn('material-info')}>
      <Subtitle>
        <Translate stringId="materialSummary" data-testid="materialSummaryLabel" />
      </Subtitle>
      <div className="table-section">
        <table>
          <thead>
            <tr>
              <th>
                <Translate stringId="mix" data-testid="mixIdDescriptionLabel" />
              </th>
              <th>
                <Translate stringId="ordered" data-testid="orderedQtyLabel" />
              </th>
              <th>
                <Translate stringId="loaded" data-testid="loadedQtyLabel" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-testid="mixIdDescriptionValue">{mixInfo.mix}</td>
              <td data-testid="orderedQtyValue">{mixInfo.orderedQty}</td>
              <td data-testid="loadedQtyValue">{mixInfo.loadedQty}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Styled>
  );
};

export default Material;
