import React, { useCallback } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Menu as AntMenu } from 'antd';
import { Translate } from '../../../localization';
import style from './style';

const Menu = ({ className, setVisible, menuItems = [], onClick, activeId }) => {
  const handleMenuClick = useCallback(
    fn => {
      setVisible(false);
      setTimeout(() => {
        fn();
      }, 200);
    },
    [setVisible]
  );

  // TODO: refactor

  return (
    <AntMenu className={cn('cx-menu', className)}>
      {menuItems.map(menuItem => {
        if (menuItem.type === 'divider') {
          return <AntMenu.Divider />;
        }

        if (menuItem.type === 'function') {
          return (
            <AntMenu.Item
              className={cn('cx-menu-item', { active: activeId === menuItem.id })}
              onClick={() => handleMenuClick(() => menuItem.fn())}
            >
              {menuItem.icon && <div className="icon">{menuItem.icon}</div>}
              <div className="label">
                <Translate text={menuItem.label} />
              </div>
            </AntMenu.Item>
          );
        }

        if (menuItem.type === 'component') {
          return (
            <AntMenu.Item key={menuItem.id} className={cn('cx-menu-item', 'text-item')}>
              {menuItem.icon && <div className="icon">{menuItem.icon}</div>}
              <div className="text-label">{menuItem.component}</div>
            </AntMenu.Item>
          );
        }

        return (
          <AntMenu.Item
            key={menuItem.id}
            className={cn('cx-menu-item', { active: activeId === menuItem.id })}
            onClick={() => handleMenuClick(() => onClick(menuItem.id))}
          >
            {menuItem.icon && <div className="icon">{menuItem.icon}</div>}
            <div className={cn('label', `menu-item-${menuItem.id}`)}>
              <Translate stringId={menuItem.stringId} data-testid={`menu-item-${menuItem.id}-label`}/>
            </div>
          </AntMenu.Item>
        );
      })}
    </AntMenu>
  );
};

export default styled(Menu)`
  ${style}
`;
