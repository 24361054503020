import cn from 'classnames';
import { Drawer, Form, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { SetupEditor } from './SetupEditor';
import style from './style';

const { Translate } = Localization;
const { H3 } = Typography;

const Styled = styled.div`
  ${style}
`;
export const SetupList = ({ typeId, config }) => {
  const { Components, resetMode } = Form.useFormContext();

  const { openDrawer } = Drawer.useDrawerContext();

  const handleClick = React.useCallback(() => {
    openDrawer({
      titleStringId: typeId,
      component: <SetupEditor config={config} />,
      onClose: resetMode,
    });
  }, [config, openDrawer, resetMode, typeId]);

  return (
    <Styled className={cn('setup-list')}>
      <div className="header">
        <div className="left">
          <H3>
            <Translate stringId={typeId} />
          </H3>
        </div>
        <div className="right">
          <Components.AddButton onClick={handleClick} size="small" stringId={`add-${typeId}`} />
        </div>
      </div>
      <div className="list-section">
        <Components.ListComponent onClick={handleClick} gridName={`connex-setup-${typeId}`} />
      </div>
    </Styled>
  );
};
