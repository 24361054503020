import cn from 'classnames';
import { Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import style from '../style';

const { Translate, Uom } = Localization;

const Styled = styled.div`
  ${style}
`;
const GeneralDeliveryInfo = ({ data }) => {
  let orderedQty,
    loadedQty = '';
  if (data?.lineItems && data?.lineItems.length > 0) {
    data?.lineItems.forEach((lineItem, key) => {
      if (lineItem.isPrimary) {
        orderedQty = <Uom uom={lineItem.orderedQuantity} />;
        loadedQty = <Uom uom={lineItem.quantity} />;
      }
    });
  }

  const unsure = '??';

  return (
    <Styled className={cn('test-form')}>
      <div className="body">
        <Translate stringId="plantName" data-testid="plantNameLabel" /> :{' '}
        <span data-testid="plantName"> {data?.origin?.description} </span>
      </div>
      <div className="body">
        <Translate stringId="sapDeliveryNumber" data-testid="sapDeliveryNumberLabel" /> :{' '}
        <span data-testid="sapDeliveryNumber"> {unsure}</span>
      </div>
      <div className="body">
        <Translate stringId="salesOrderNumber" data-testid="salesOrderNumberLabel" /> :{' '}
        <span data-testid="salesOrderNumber"> {unsure}</span>
      </div>
      <div className="body">
        <Translate stringId="wcDateTime" data-testid="wcDateTimeLabel" /> :{' '}
        <span data-testid="wcDateTime"> {unsure}</span>
      </div>
      <div className="body">
        <Translate stringId="customerId" data-testid="customerIdLabel" /> :{' '}
        <span data-testid="customerId">{data?.customerParty?.id}</span>
      </div>
      <div className="body">
        <Translate stringId="po" data-testid="poLabel" /> : <span data-testid="po">{data?.purchaseOrder} </span>
      </div>
      <div className="body">
        <Translate stringId="orderedByName" data-testid="orderedByNameLabel" /> :{' '}
        <span data-testid="orderedByName"> {unsure}</span>
      </div>
      <div className="body">
        <Translate stringId="orderedByPhone" data-testid="orderedByPhoneLabel" /> :{' '}
        <span data-testid="orderedByPhone"> {unsure}</span>
      </div>
      <div className="body">
        <Translate stringId="resourceId" data-testid="resourceIdLabel" /> :{' '}
        <span data-testid="resourceId"> {unsure}</span>
      </div>
      <div className="body">
        <Translate stringId="loadedQtyLabel" data-testid="loadedQtyLabel" /> :{' '}
        <span data-testid="loadedQty">{loadedQty}</span>
      </div>
      <div className="body">
        <Translate stringId="totalLoadedQty" data-testid="totalLoadedQtyLabel" /> :{' '}
        <span data-testid="totalLoadedQty"> {unsure}</span>
      </div>
      <div className="body">
        <Translate stringId="orderedQtyLabel" data-testid="orderedQtyLabel" /> :{' '}
        <span data-testid="orderedQty">{orderedQty} </span>
      </div>
      <div className="body">
        <Translate stringId="spacing" data-testid="spacingLabel" /> : <span data-testid="spacing"> {unsure}</span>
      </div>
      <div className="body">
        <Translate stringId="deliveryFlow" data-testid="deliveryFlowLabel" /> :{' '}
        <span data-testid="deliveryFlow"> {unsure}</span>
      </div>
      <div className="body">
        <Translate stringId="unloadingMethod" data-testid="unloadingMethodLabel" /> :{' '}
        <span data-testid="unloadingMethod"> {unsure}</span>
      </div>
      <div className="body">
        <Translate stringId="timeOnSite" data-testid="timeOnSiteLabel" /> :{' '}
        <span data-testid="timeOnSite"> {unsure}</span>
      </div>
    </Styled>
  );
};

export default GeneralDeliveryInfo;
