import { Select as AntSelect } from 'antd';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import dropdownRenderer from './dropdownRenderer';
import style from './style';

const StyledAntSelect = styled(AntSelect)`
  ${style}
`;

const { Option } = AntSelect;

export const Select = ({ options, busy, innerRef, testId, inferFromListValue, ...props }) => {
  // const suffixIcon = React.useMemo(() => {
  //   return busy ? (
  //     <i style={{ color: 'var(--form-element-text-color)' }} className="fa-regular fa-spin fa-spinner" />
  //   ) : undefined;
  // }, [busy]);

  const dataTestId = { 'data-testid': testId };

  return (
    <StyledAntSelect
      ref={innerRef}
      // suffixIcon={suffixIcon}
      dropdownRender={dropdownRenderer}
      dropdownStyle={{ backgroundColor: 'var(--color-background-dropdown)', color: 'var(--color-text-dropdown)' }}
      className={cn({ busy })}
      {...dataTestId}
      {...props}
    >
      {options?.map(option => (
        <Option key={option.id} value={option.id}>
          {option.label}
        </Option>
      ))}
    </StyledAntSelect>
  );
};

Select.Option = Option;
