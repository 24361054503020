import React, { useMemo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useViewport } from '../../../../../../hooks/useViewport';
import style from './style';
import { Drawer as AntDrawer } from 'antd';

const Drawer = styled(AntDrawer)`
  ${style}
`;

const PreferencesDrawer = ({ isOpen, onClose, title, children }) => {
  const viewport = useViewport();

  const drawerWidth = useMemo(() => {
    return Math.min(650, viewport.width);
  }, [viewport]);

  return (
    <Drawer
      className={cn('preferences-drawer')}
      visible={isOpen}
      onClose={onClose}
      title={title}
      width={drawerWidth}
      destroyOnClose
    >
      {children}
    </Drawer>
  );
};

export default PreferencesDrawer;
