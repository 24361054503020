import { isEmpty, isUndefined } from 'lodash';

export default (obj, messages) => {
  if (isEmpty(obj)) return;

  const deprecated = Object.keys(obj).filter(key => !isUndefined(obj[key]));

  const messageDetail = deprecated.map(key => `      -  ${key} ${messages[key] ? `(${messages[key]})` : ''}`);

  if (deprecated?.length) {
    console.log(`WARNING:  The following props have been deprecated: \n${messageDetail.join('\n')}\n`);
    // console.log(new Error().stack);
    console.trace();
  }
};
