import { css } from 'styled-components';

export default css`
  display: flex;
  height: 100%;
  .sidebar-content {
    width: 250px;
    border-right: var(--border-sidebar);
    background-color: var(--color-background-sidebar);
    transition: all 0.3s;
    padding: 10px 20px;
  }
  .main-content {
    
    width: 100%;
  }
`;
