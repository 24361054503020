import React from 'react';
import styled from 'styled-components';
import { Translate } from '../../localization';
import { H3 } from '../../typography';

const Styled = styled(H3)`
  display: block;
  height: 27px;
`;

export const CardTitle = ({ titleStringId }) => {
  if (!titleStringId) return null;
  return (
    <Styled>
      <Translate stringId={titleStringId} />
    </Styled>
  );
};
