import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import Menu from '../menu/Menu';
import { Dropdown } from '../../dropdown/Dropdown';

const ToggleableDropdown = ({ className, children, trigger, placement, onClick, activeId, menuItems }) => {
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = useCallback(flag => {
    setVisible(flag);
  }, []);

  return (
    <div className={cn(className)}>
      <Dropdown
        overlay={<Menu setVisible={setVisible} menuItems={menuItems} onClick={onClick} activeId={activeId} />}
        onVisibleChange={handleVisibleChange}
        visible={visible}
        trigger={trigger}
        placement={placement}
      >
        {children}
      </Dropdown>
    </div>
  );
};

export default ToggleableDropdown;
