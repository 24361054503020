import React from 'react';
import { LongDate } from './LongDate';
import { Time } from './Time';

export const LongDateTime = ({ date, hideTimeZone = false, withDayOfWeek = false }) => {
  return (
    <>
      <LongDate date={date} withDayOfWeek={withDayOfWeek} /> <Time date={date} hideTimeZone={hideTimeZone} />
    </>
  );
};
