import { isEmpty } from 'lodash';

export default obj => {
  if (isEmpty(obj)) return;

  const failed = Object.keys(obj).filter(key => isEmpty(obj[key]));

  if (failed?.length) {
    console.trace();
    throw new Error(`Required: ${failed.join(', ')}`);
  }
};
