import { Steps as AntSteps } from 'antd';
import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import style from './style';

const { Step } = AntSteps;

const Styled = styled.div`
  ${style}
`;

export const Steps = ({ steps, currentStep }) => {
  return (
    <Styled className={cn('wizard')}>
      <div className="overview">
        <AntSteps current={currentStep}>
          {steps.map(step => (
            <Step title={step.title} />
          ))}
        </AntSteps>
      </div>
    </Styled>
  );
};
