import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';

const Styled = styled.span`
  &.required-indicator {
    font-size: 0.8em;
    vertical-align: super;
    margin-left: 3px;
    color: var(--color-requiredIndicator);

    &.disabled {
      color: var(--color-requiredIndicator-disabled);
    }
  }
`;

export const RequiredIndicator = ({ disabled, required, testId }) => {
  const dataTestId = { 'data-testid': `${testId}-requiredIndicator` };

  if (!required) return null;

  return (
    <Styled className={cn('required-indicator', { disabled })} {...dataTestId}>
      *
    </Styled>
  );
};
