import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import cn from 'classnames';
import { Core, Form, Typography } from 'connex-cds';

const { H1 } = Typography;

const Styled = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Confirmation = () => {
  const navigate = useNavigate();
  const { resetForm } = Form.useFormContext();

  const handleClick = React.useCallback(() => {
    resetForm({ reinitialize: true });
    navigate('/mt');
  }, [navigate, resetForm]);

  return (
    <Styled className={cn('confirmation')}>
      <div>
        <H1>Completed</H1>
      </div>
      <div>
        <Core.Button type="primary" stringId="Start Over" data-testid="next-button" onClick={handleClick} />
      </div>
    </Styled>
  );
};
