import { css } from 'styled-components';

export default css`
  padding: 12px;
  border-radius: var(--radius-card);
  background-color: var(--color-background-card);
  min-width: 200px;
  transition: all 0.3s;

  &.clickable {
    cursor: pointer;
  }

  &.success {
    border-left: 4px solid var(--color-border-card-success);
  }
  &.warning {
    border-left: 4px solid var(--color-border-card-warning);
  }
  &.error {
    border-left: 4px solid var(--color-border-card-error);
  }
`;
