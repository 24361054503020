import { find, get } from 'lodash';
import React from 'react';

export const FILTER_STRATEGY = {
  INCLUDES: 'includes',
  STARTS_WITH: 'startsWith',
  EQUALS: 'equals',
};

export const defaultFilterFn = (item, filterStrings, strategy = FILTER_STRATEGY.INCLUDES) => {
  if (strategy === FILTER_STRATEGY.EQUALS) {
    return filterStrings.some(filterString => item?.toUpperCase?.() === filterString?.toUpperCase?.());
  }

  return filterStrings.some(filterString => item?.toUpperCase?.()?.[strategy]?.(filterString?.toUpperCase?.()));
};

export const getComparator = (columnKey, columns) => {
  const column = find(columns, { key: columnKey });
  const sortKey = column.sortKey || columnKey;

  return (
    column.sortFn ||
    ((a, b) => {
      const aValue = column.getValue?.(a) || get(a, sortKey, '');
      const bValue = column.getValue?.(b) || get(b, sortKey, '');

      if (aValue < bValue) {
        return -1;
      }
      if (aValue > bValue) {
        return 1;
      }
      return 0;
    })
  );
};
