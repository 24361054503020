import { set, get } from 'lodash';
import React, { useContext } from 'react';
import { v4 as uuid } from 'uuid';
import { useFormContext } from '../../context-provider/FormProvider';

const ArrayContext = React.createContext();

export const useArrayContext = () => {
  const context = useContext(ArrayContext);
  if (!context) {
    throw new Error(`useArrayContext cannot be rendered outside of the ArrayContext context provider`);
  }
  return context;
};

export const ArrayContextProvider = ({ value, children }) => {
  const formContext = useFormContext();

  const [activeRowId, setActiveRowId] = React.useState(null);

  // ensure fieldConfig has a keyPath (default to ___id)
  const fieldConfig = React.useMemo(() => {
    return { ...(value?.fieldConfig || {}), keyPath: value?.fieldConfig?.keyPath || '___id' };
  }, [value?.fieldConfig]);

  const arrayValue = React.useMemo(() => {
    const values = get(formContext.values, fieldConfig.path);
    const keyPath = fieldConfig?.keyPath;

    // ensure each element in the array has a key.
    return values?.map?.(element => {
      if (!get(element, keyPath)) {
        set(element, keyPath, uuid());
      }
      return element;
    });
  }, [formContext.values, fieldConfig.keyPath, fieldConfig.path]);

  return (
    <ArrayContext.Provider value={{ ...value, value: arrayValue, fieldConfig, activeRowId, setActiveRowId }}>
      {children}
    </ArrayContext.Provider>
  );
};
