import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { PaymentMethodList } from './PaymentMethodList';
import style from './style';
import { Form, Drawer } from 'connex-cds';

const Styled = styled.div`
  ${style}
`;

export const PaymentMethodSetup = props => {
  const { Components } = Form.useFormContext();
  const { DrawerProvider } = Drawer;

  const { PaymentMethod } = Components;

  return (
    <Styled className={cn('payment-method-list')}>
      <PaymentMethod>
        <DrawerProvider>
          <PaymentMethodList />
        </DrawerProvider>
      </PaymentMethod>
    </Styled>
  );
};
