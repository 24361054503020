import { css } from 'styled-components';

export default css`
  width: 100%;
  border-collapse: collapse;

  th {
    background-color: var(--color-background-simpleGrid-header);
    border: 1px solid var(--color-border-simpleGrid-header);
  }

  tr {
    &.active {
      td {
        background-color: var(--color-background-simpleGrid-highlightedRow);
        color: var(--color-text-simpleGrid-highlightedRow);
      }
    }

    td {
      transition: all 0.3s;
      padding: 0 5px;
      border: 1px solid var(--color-border-simpleGrid-cell);
      background-color: var(--color-background-simpleGrid-cell);
      cursor: ${({ $onRowClick, $rowClickDisabled }) => ($onRowClick && !$rowClickDisabled ? 'pointer' : 'inherit')};
    }
  }
`;
