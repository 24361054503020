import { css } from 'styled-components';

export default css`
  .returned-material-content {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  .water-added-content {
    margin: 0 10px;
  }
  table {
    width: 100%;
    th {
      text-align: left;
    }
    tr {
      &.second-row {
        line-height: 14px;
        &:not(:last-child) {
          border-bottom: 1px solid #ccc;
          td {
            padding-bottom: 10px;
          }
        }
      }
    }
  }
`;
