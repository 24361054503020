import propTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';

export const Currency = ({ value, currency }) => <FormattedNumber value={value} style="currency" currency={currency} />;

Currency.propTypes = {
  value: propTypes.number,
  currency: propTypes.string,
};
