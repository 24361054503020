import React from 'react';
import { useForm as useReactHookForm, Controller } from 'react-hook-form';
import getFieldComponent from './getFieldComponent';
import { camelCase, toUpper } from 'lodash';

const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

export const useForm = config => {
  const formMethods = useReactHookForm({ mode: 'onChange' });

  const { control, formState, getValues } = formMethods;

  const { errors, isValid } = formState;

  const Components = config?.fields?.reduce((acc, fieldConfig) => {
    const Component = getFieldComponent(fieldConfig);

    acc[pascalCase(fieldConfig.id)] = () => (
      <Controller
        control={control}
        name={fieldConfig.id}
        render={({ field }) => {
          return <Component key={fieldConfig.id} {...fieldConfig} {...field} />;
        }}
        rules={{ required: fieldConfig.required ? fieldConfig.required.message : false }}
      />
    );
    return acc;
  }, {});

  console.log('useForm', Components);

  return {
    errors,
    isValid,
    getValues,
    Components,
  };
};
