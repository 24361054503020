import cn from 'classnames';
import { useQrCode } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  display: flex;
  justify-content: center;
`;

const mockData = `
GENERAL
===================================
Plant Name : Middlesbrough Depot
SAP Delivery number (BCC Number) : ??
Sales order number and item line : ??
Date and time W/C contact : ??
Customer ID : My Customer
PO Number : 12345
Order placed by name : ??
Order placed by phone number : ??
Resource ID : ??
Loaded QTY : 30 Tons
Total loaded qty including this load : ??
Ordered QTY : 100 Tons
Load space : ??
Delivery flow : ??
Unloading Method : ??
Total on site time/ordered unloading time : ??



MATERIAL INFORMATION
===================================
Material : 1 / 1
Sales text (material ID) : ??
Strength class : ??
Environment exposure : ??
Met exposure classes : ??
Dmax : ??
Standard (certification) : ??
Customer workability target : ??
Stone Quality : ??
Sand Quality : ??
Target W/C ratio from recipe : ??
Control Class : ??
Chloride Class : ??
Type of cement and strengthclass 1 : ??
Type of cement and strengthclass 2 : ??
Type of addition : ??
Type of admixture : ??
Ordered Temperature : ??
Type and content of fibers 1 : ??
Type and content of fibers 2 : ??
Calculated maturity time from W/C contact time : ??
`;

const QRCode = () => {
  const qrCode = useQrCode({ data: mockData || ' ' });

  return (
    <Styled className={cn('qr-tab')}>
      <div>{qrCode}</div>
    </Styled>
  );
};

export default QRCode;
