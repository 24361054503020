import { css } from 'styled-components';

export default css`
  padding: 5px;
  .header {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    .left {
      flex: 1;
      h3 {
        margin-bottom: 0;
      }
    }
    .right {
      display: flex;
      gap: 15px;
    }
  }
  .payment-method-editor {
    .actions {
      display: flex;
      gap: 15px;
      & > * {
        &:first-child {
          flex: 1;
        }
        &:last-child {
          display: flex;
          gap: 15px;
        }
      }
    }
  }
`;
