import { useCallback, useEffect, useRef } from 'react';

// https://github.com/facebook/react/issues/5465#issuecomment-157888325
export const makeCancelable = promise => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(val => {
      return isCanceled ? null : resolve(val);
    });
    promise.catch(error => {
      return isCanceled ? null : reject(error);
    });
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
};

export const useApiCall = fn => {
  const apiCall = useRef(fn);

  const cancelablePromise = useRef({ cancel: () => {} });

  const makeApiCall = useCallback(
    (...args) => {
      const response = apiCall.current(...args);
      const cancelable = makeCancelable(response);
      cancelablePromise.current = cancelable;
      return cancelable.promise;
    },
    [cancelablePromise]
  );

  useEffect(() => {
    return () => {
      cancelablePromise.current.cancel();
    };
  }, [cancelablePromise]);

  return makeApiCall;
};
