import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import { Form, Icons, Core, Layout, Typography, Localization } from 'connex-cds';

const { Button } = Core;
const { Column, Row, Container } = Layout;
const { Subtitle, Helper } = Typography;
const { Translate, ShortDateTime } = Localization;
const { Edit } = Icons;

const Styled = styled(Column)`
  ${style}
`;
export const Comments = props => {
  const { values } = Form.useFormContext();

  return (
    <Styled className={cn('comments')}>
      <Row flex={1}>
        <Container flex={1} style={{ padding: '5px 10px' }}>
          <Subtitle>
            <Translate stringId="driverComment" data-testid="driverCommentLabel" />
          </Subtitle>
          <div className="comment-section" style={{ padding: '5px' }}>
            <div className="comment-date-time">
              <div>
                <Helper>
                  <ShortDateTime date={values?.driverComment.timestamp} withDayOfWeek />
                </Helper>
              </div>
            </div>
            <div data-testid="driverCommentValue">{values?.driverComment.text}</div>
          </div>
        </Container>
      </Row>
      <Row flex={1}>
        <Container flex={1} style={{ padding: '5px 10px' }}>
          <Subtitle>
            <Translate stringId="customerComment" data-testid="customerCommentLabel" />
          </Subtitle>
          <div className="comment-section">
            <div className="comment-date-time">
              <Helper>
                <ShortDateTime date={values?.customerComment.timestamp} withDayOfWeek />
              </Helper>
            </div>
            <div data-testid="customerCommentValue">{values?.customerComment.text}</div>
          </div>
        </Container>
      </Row>
    </Styled>
  );
};
