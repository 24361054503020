import React from 'react';
import styled from 'styled-components';
import { Translate } from '../../localization';

const Styled = styled(Translate)`
  display: block;
`;

export const CardBody = ({ stringId }) => {
  if (!stringId) return null;
  return <Styled stringId={stringId} />;
};
