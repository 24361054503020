import { Checkbox as AntCheckbox } from 'antd';
import React from 'react';
import styled from 'styled-components';
import style from './style';

const StyledAntCheckbox = styled(AntCheckbox)`
  ${style}
`;

export const Checkbox = ({ innerRef, value, testId, onChange, ...props }) => {
  const dataTestId = { 'data-testid': testId };

  const handleClick = React.useCallback(
    e => {
      const { checked } = e.target;
      onChange(checked);
    },
    [onChange]
  );

  return (
    <StyledAntCheckbox className={`cds-checkbox`} onClick={handleClick} checked={value} {...props} {...dataTestId} />
  );
};
