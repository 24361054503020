import cn from 'classnames';
import { Form } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  .buttons {
    display: flex;
    justify-content: flex-end;
  }
`;

const LoginForm = props => {
  const { Components } = Form.useFormContext();

  const { Username, Password, SubmitButton } = Components;


  return (
    <Styled className={cn('login')}>
      <div>
        <Username />
      </div>
      <div>
        <Password />
      </div>
      <div className="buttons">
        <SubmitButton stringId="login" testId="login-button" />
      </div>
    </Styled>
  );
};

export default LoginForm;
