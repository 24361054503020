import { TimePicker as AntTimePicker } from 'antd';
import cn from 'classnames';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import React from 'react';
import styled from 'styled-components';
import { useLocalizationContext } from '../../localization';
import style from './style';

const Styled = styled.div`
  ${style}
`;
export const TimePicker = ({ local = false, onChange, value, placeholder = '', ...props }) => {
  const { timeZone } = useLocalizationContext();

  const [_value, setValue] = React.useState(null);

  const format = 'h:mm A';

  const handleChange = React.useCallback(
    momentValue => {
      if (momentValue) {
        const isoVal = momentValue?.toISOString?.(true);

        if (isoVal) {
          const tzAdjusted = !timeZone
            ? isoVal
            : DateTime.fromISO(isoVal).setZone(timeZone, { keepLocalTime: !local }).toUTC().toISO();

          // Weird issue: if there's an existing value, the above forces the displayed time into the browser's time zone.
          // What follows is a workaround until I can figure this out.

          if (value) {
            onChange?.(DateTime.fromISO(isoVal).setZone(timeZone, { keepLocalTime: false }).toUTC().toISO());
          } else {
            onChange?.(tzAdjusted);
          }
        }
      } else {
        onChange(null);
      }
    },
    [local, onChange, timeZone, value]
  );

  React.useEffect(() => {
    if (value) {
      const tzAdjusted = !timeZone ? value : DateTime.fromISO(value).setZone(timeZone).toUTC().toISO();
      setValue(local ? moment(tzAdjusted) : moment.tz(tzAdjusted, timeZone));
    } else {
      setValue('');
    }
  }, [local, timeZone, value]);

  return (
    <Styled className={cn('time-picker')}>
      <AntTimePicker
        {...props}
        format={format}
        use12Hours
        onChange={handleChange}
        value={_value}
        placeholder={placeholder}
      />
    </Styled>
  );
};
