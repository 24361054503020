import React, { useCallback } from 'react';
import cn from 'classnames';
import { find } from 'lodash';

const Toggle = ({ className, children, onClick, activeId, menuItems }) => {
  const toggle = useCallback(() => {
    const target = find(menuItems, item => item.id !== activeId);
    onClick(target.id);
  }, [onClick, activeId, menuItems]);

  return (
    <div className={cn(className)} onClick={toggle}>
      {children}
    </div>
  );
};

export default Toggle;
