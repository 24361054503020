import CryptoJS from 'crypto-js';

export const decode = (key, s) => {
  if (key && s) {
    const bytes = CryptoJS.AES.decrypt(s, key);

    return bytes.toString(CryptoJS.enc.Utf8);
  }

  return null;
};

export const encode = (key, s) => {
  if (key && s) {
    return CryptoJS.AES.encrypt(s, key).toString();
  }

  return null;
};

export const salt = '434F4E4E4558';

export default {
  decode,
  encode,
  salt,
};
