import { css } from 'styled-components';

export default css`
  height: 35px;
  font-size: 14px;
  line-height: 16.41px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
  text-transform: uppercase;
  width: fit-content;
  transition: all 0.3s;
  padding: 8px 16px;

  &&& {
    ${({ size }) => {
      if (size === 'small') {
        return 'height: 24px; font-size: 12px; line-height: 8px; padding: 4px 12px;';
      }
    }}

    background-color: var(--color-background-button-secondary);
    color: var(--color-text-button-secondary);
    border: 1px solid var(--color-border-button-secondary);
    border-radius: 4px;

    &:disabled {
      background-color: var(--color-background-button-secondary-disabled);
      color: var(--color-text-button-secondary-disabled);
      border: 1px solid var(--color-border-button-secondary-disabled);
    }

    &.ant-btn-primary {
      background-color: var(--color-background-button-primary);
      color: var(--color-text-button-primary);
      border: 1px solid var(--button-primary-border-color);
      &:disabled {
        ${({ $suppressDisabledStyling }) =>
          $suppressDisabledStyling
            ? ''
            : `
        background-color: var(--color-background-button-primary-disabled);
        color: var(--color-text-button-primary-disabled);
        border: 1px solid var(--button-primary-disabled-border-color);`}
      }
      &:hover {
        &:not(:disabled) {
          background-color: var(--color-background-button-primary-hover);
        }
      }
      &.ant-btn-dangerous {
        background-color: var(--color-background-button-primary-dangerous);
        color: var(--color-text-button-primary-dangerous);
        border: 1px solid var(--button-primary-dangerous-border-color);

        &:disabled {
          background-color: var(--color-background-button-primary-disabled);
          color: var(--color-text-button-primary-disabled);
          border: 1px solid var(--button-primary-disabled-border-color);
        }
        &:hover {
          &:not(:disabled) {
            background-color: var(--color-background-button-primary-dangerous-hover);
          }
        }
      }
    }
    .ant-btn-loading-icon {
      margin-right: 10px;
    }
  }
`;
