import { camelCase, find, isUndefined, toUpper } from 'lodash';
import React from 'react';
import { FIELD_TYPES } from '../fieldTypes';
import getFieldComponent from '../hook/getFieldComponent';

const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

export default ({ config, setFieldValue }) => {
  const { fields, validationSchema } = config;

  return fields?.reduce((acc, fieldConfig) => {
    const Component = getFieldComponent(fieldConfig);

    // // Some components add keys to the component (e.g. Array)
    // const controlledKeys = Object.keys(Component);

    const tests = validationSchema?.describe?.()?.fields?.[fieldConfig.path]?.tests;
    let isRequired = !!find(tests, { name: 'required' });

    if (fieldConfig.dataType === FIELD_TYPES.array) {
      // required means min > 0;
      const min = find(tests, { name: 'min' });
      isRequired = min?.params?.min > 0;
    }

    const path = fieldConfig.path
      .split('.')
      .concat([fieldConfig.valuePath])
      .filter(x => !isUndefined(x));

    const onChange = fieldConfig.onChange
      ? val => setFieldValue(fieldConfig.path, fieldConfig.onChange(val))
      : value => setFieldValue(path, value);

    const controlledComponent = props => {
      return (
        <Component
          key={fieldConfig.id}
          fieldConfig={fieldConfig}
          {...props}
          name={fieldConfig.path}
          onChange={onChange}
          isRequired={isRequired}
        />
      );
    };

    // Ensure all keys of the original Component are passed to the controlledComponent
    // controlledKeys.forEach(key => {
    //   controlledComponent[key] = Component[key];
    // });

    acc[pascalCase(fieldConfig.name || fieldConfig.path)] = controlledComponent;

    return acc;
  }, {});
};
