import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import { Form, Drawer } from 'connex-cds';

const Styled = styled.div`
  ${style}
`;
export const PaymentMethodEditor = props => {
  const { Components, values } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const { CancelButton, SaveButton, DeleteButton, Groups, General, TabTwo, TabThree } = Components;

  const { Id } = General.Components;
  const { Name } = TabTwo.Components;
  const { Status, Invoiceable } = TabThree.Components;

  const handleSave = React.useCallback(() => {
    console.log('saving', values);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('saved');
        closeDrawer();
        resolve();
      }, 2000);
    });
  }, [closeDrawer, values]);

  return (
    <Styled className={cn('setup-editor payment-method-editor')}>
      {/*<Id />*/}
      {/*<Name />*/}
      {/*<Invoiceable />*/}
      {/*<Status />*/}

      <Groups>
        <General>
          <Id />
        </General>
        <TabTwo>
          <Name />
        </TabTwo>
        <TabThree>
          <Status />
          <Invoiceable />
        </TabThree>
      </Groups>
      <div className="actions">
        <div>
          <DeleteButton onDelete={closeDrawer} />
        </div>
        <div>
          <CancelButton onCancel={closeDrawer} enabled />
          <SaveButton onSave={handleSave} suppressDisabledStyling />
        </div>
      </div>
    </Styled>
  );
};
