import { Form } from 'connex-cds';
import * as Yup from 'yup';
const { FIELD_TYPES } = Form;

// Field grouping is optional.

const fields = {
  icon: <i className="icon fa-light fa-credit-card" />,
  validationSchema: Yup.object().shape({
    id: Yup.string().required('required'),
    name: Yup.string().required('required'),
    status: Yup.string().required('required'),
  }),
  fields: [
    { path: 'id', labelStringId: 'id', testId: 'id', dataType: FIELD_TYPES.string },
    { path: 'name', labelStringId: 'name', testId: 'name', dataType: FIELD_TYPES.string },
    { path: 'status', labelStringId: 'status', testId: 'status', dataType: FIELD_TYPES.string },
    {
      path: 'invoiceable',
      labelStringId: 'invoiceable',
      testId: 'invoiceable',
      dataType: FIELD_TYPES.string,
      listValues: [
        { id: 'YES', label: 'Yes' },
        { id: 'NO', label: 'No' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
    },
  ],
};

const groups = {
  icon: <i className="icon fa-light fa-credit-card" />,
  validationSchema: Yup.object().shape({
    id: Yup.string().required('required'),
    name: Yup.string().required('required'),
    status: Yup.string().required('required'),
  }),
  groups: [
    {
      groupNameStringId: 'general',
      groupId: 'general', // Component Name
      fields: [{ path: 'id', labelStringId: 'id', testId: 'id', dataType: FIELD_TYPES.string }],
    },
    {
      groupNameStringId: 'tabTwoStringId',
      groupId: 'tab-two', // Component Name
      fields: [{ path: 'name', labelStringId: 'name', testId: 'name', dataType: FIELD_TYPES.string }],
    },
    {
      groupNameStringId: 'tabThreeStringId',
      groupId: 'tab-three', // Component Name
      fields: [
        { path: 'status', labelStringId: 'status', testId: 'status', dataType: FIELD_TYPES.string },
        {
          path: 'invoiceable',
          labelStringId: 'invoiceable',
          testId: 'invoiceable',
          dataType: FIELD_TYPES.string,
          listValues: [
            { id: 'YES', label: 'Yes' },
            { id: 'NO', label: 'No' },
          ],
          listValuePath: 'id',
          listDisplayPath: 'label',
        },
      ],
    },
  ],
};

export default groups;
