import React from 'react';
import deprecated from '../../util/validation/deprecated';
import TranslateString from '../localization/util/translate-string-id/TranslateString';

export const Translate = ({ stringId, values, testId: testIdDeprecated, 'data-testid': dataTestId }) => {
  deprecated({ testId: testIdDeprecated }, { testId: 'Use data-testid' });
  const dataAttributes = { 'data-stringid': stringId, 'data-testid': dataTestId || testIdDeprecated };

  return (
    <span {...dataAttributes}>
      <TranslateString id={stringId} values={values} />
    </span>
  );
};
