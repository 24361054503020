import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import Footer from '../unauthenticated/footer/Footer';
import Header from './header/Header';
import { Sidebar } from './sidebar/Sidebar';
import style from './style';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { Layout } from 'connex-cds';

const { HeaderFooter } = Layout;

const Styled = styled.div`
  ${style}
`;

const AuthenticatedLayout = props => {
  return (
    <Styled className={cn('authenticated-layout')}>
      <HeaderFooter>
        <Header />
        <Outlet />
        <Footer />
      </HeaderFooter>
    </Styled>
  );
};

export default AuthenticatedLayout;
