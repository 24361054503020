import React from 'react';
import { Spinner } from '../../../spinner/Spinner';
import { ConnexDataGridProvider } from '../../ConnexDataGrid';
import DefaultHeader from '../../DefaultHeader';
import { Grid } from '../index';

const StandardConnexDataGrid = props => (
  <ConnexDataGridProvider {...props}>
    <Spinner spin={props.loading}>
      <DefaultHeader allowExport={props?.allowExport} />
      <Grid />
    </Spinner>
  </ConnexDataGridProvider>
);

export default StandardConnexDataGrid;
