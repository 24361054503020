import { isEmpty, isUndefined } from 'lodash';

export default obj => {
  if (isEmpty(obj)) return;
  const keys = Object.keys(obj);

  const passed = keys.filter(key => !isUndefined(obj[key]));

  if (passed?.length !== 1) {
    console.trace();
    throw new Error(`Exactly one of the following is required: ${keys.join(', ')}`);
  }
};
