import { message } from '../core';

let _config;
let _source;

export const configure = ({ errorHandler, ...options }) => {
  _config = options;
};

export const getConfig = apiName => _config[apiName];

export const handleError = msg => {
  message.error(msg);
};

export const setSource = source => {
  _source = source;
};

export const getSource = () => _source;

export const API = {
  configure,
  getConfig,
  getSource,
  setSource,
  handleError,
};
