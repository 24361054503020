import { css } from 'styled-components';

export default css`
  transition: all 0.3s;
  user-select: none;

  &.h1 {
    font-size: 32px;
  }

  &.h2 {
    font-size: 24px;
  }

  &.h3 {
    font-size: 20px;
  }

  &.subtitle {
    font-size: 16px;
    font-weight: 500;
  }

  &.body {
    font-size: 16px;
    line-height: 150%;
  }

  &.helper {
    font-size: 12px;
    line-height: 16px;
  }
`;
