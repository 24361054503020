import React from 'react';
import styled from 'styled-components';

const Styled = styled.span`
  cursor: pointer;
`;

export const Edit = ({ onClick }) => {
  if (onClick)
    return (
      <Styled onClick={onClick}>
        <i className="fa-light fa-pen-to-square" />
      </Styled>
    );

  return <i className="fa-light fa-pen-to-square" />;
};
