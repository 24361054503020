import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../../assets/connex-logo-dark-theme.svg';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const Header = props => {
  return (
    <Styled className={cn('header')}>
      <Logo height={25} />
    </Styled>
  );
};

export default Header;
