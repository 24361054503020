import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import { Form, Core, Layout, Typography, Localization } from 'connex-cds';
const { Column, Row, Container } = Layout;
const { Subtitle, Helper } = Typography;
const { Translate, Uom, Time } = Localization;

const Styled = styled(Column)`
  ${style}
`;
export const ProductsAndServices = props => {
  const { values } = Form.useFormContext();

  return (
    <Styled className={cn('products-and-services')}>
      <Row flex={1}>
        <Container flex={1} style={{ padding: '5px 10px' }}>
          <Subtitle>
            <Translate stringId="returnedMaterial" data-testid="returnedMaterialLabel" />
          </Subtitle>
          <div className="returned-material-content">
            <table>
              <thead>
                <tr>
                  <th>
                    <Translate stringId="concreteOnTruck" data-testid="returnConcreteOnTruckLabel" />
                  </th>
                  <th>
                    <Translate stringId="reason" data-testid="reasonLabel" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-testid="returnConcreteOnTruckValue">
                    <Uom uom={values?.returnConcreteOnTruck} />
                  </td>
                  <td data-testid="reasonValue">{values?.returnReason.label}</td>
                  <td data-testid="returnConcreteTimeValue">
                    <Time date={values?.returnConcreteTime} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Container>
      </Row>
      <Row flex={2}>
        <Container flex={1} style={{ padding: '5px 10px' }}>
          <Subtitle>
            <Translate stringId="waterAdded" data-testid="waterAddedLabel" />
          </Subtitle>
          <div className="water-added-content">
            <table>
              <thead>
                <tr>
                  <th>
                    <Translate stringId="reason" data-testid="waterAddReasonLabel" />
                  </th>
                  <th>
                    <Translate stringId="quantity" data-testid="waterAddQuantityLabel" />
                  </th>
                  <th>
                    <Translate stringId="onBoard" data-testid="waterAddOnBoardLabel" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {values?.water &&
                  values.water.map((e, key) => {
                    return (
                      <>
                        <tr key={key}>
                          <td data-testid="waterAddReasonValue">{e.a?.b?.reason.description}</td>
                          <td data-testid="waterAddQuantityValue">
                            <Uom uom={e.quantity} />
                          </td>
                          <td data-testid="waterAddConcreteOnTruckValue">
                            <Uom uom={e.concreteOnTruck} />
                          </td>
                        </tr>
                        <tr className="second-row">
                          <td colSpan={4}>
                            <Helper>
                              <Translate stringId="addedBy" data-testid="addedByLabel" /> : {e.addedBy.label} at{' '}
                              <Time date={e.time} hideTimeZone />
                            </Helper>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Container>
      </Row>
      <Row flex={2}>
        <Container flex={1} style={{ padding: '5px 10px' }}>
          <Subtitle>
            <Translate stringId="materialService" data-testid="materialServiceLabel" />
          </Subtitle>
          <div className="material-service-content">
            <table>
              <thead>
                <tr>
                  <th>
                    <Translate stringId="materialService" data-testid="materialServiceLabel" />
                  </th>
                  <th>
                    <Translate stringId="quantity" data-testid="materialServiceQuantityLabel" />
                  </th>
                  <th>
                    <Translate stringId="onBoard" data-testid="materialServiceOnBoardLabel" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {values?.materialService &&
                  values.materialService.map((e, key) => {
                    return (
                      <>
                        <tr key={key}>
                          <td data-testid="materialServiceValue">{e.product.description}</td>
                          <td data-testid="materialServiceQuantityValue">
                            <Uom uom={e.quantity} />
                          </td>
                          <td data-testid="materialServiceOnBoardValue">
                            <Uom uom={e.concreteOnTruck} />
                          </td>
                        </tr>
                        <tr className="second-row">
                          <td colSpan={4}>
                            <Helper>
                              <Translate stringId="addedBy" data-testid="addedByLabel" /> : {e.addedBy.label} at{' '}
                              <Time date={e.time} hideTimeZone />
                            </Helper>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Container>
      </Row>
    </Styled>
  );
};
