import * as yup from 'yup';
import fields from './field-config';

const getShape = config =>
  config.reduce((acc, field) => {
    let initialSchema = yup[field.dataType]();

    // if (field.dataType === FIELD_TYPES.array && field.arrayOf) {
    //   initialSchema = initialSchema.of(prepareValidation(field.arrayOf));
    // }

    let schema;

    if (field.validation) {
      schema = Object.keys(field.validation).reduce((fieldAcc, validationType) => {
        fieldAcc = fieldAcc[validationType](...field.validation[validationType]);
        return fieldAcc;
      }, initialSchema);
    }

    acc[field.id] = schema;

    return acc;
  }, {});

const getFormConfig = requiredKeys => {
  // validationSchema: Yup.object().shape({
  //   water: Yup.array().min(1),
  // }),

  const validationSchema = yup.object().shape(getShape(requiredKeys));

  const config = {
    validationSchema,
    fields,
  };

  return config;
};

export default getFormConfig;
