import { css } from 'styled-components';

export default css`
  &&& {
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all .3s;
    .rdg {
      flex: 1;
      --background-color: var(--color-background-grid);
      //--header-background-color: #21487c;
      --border-color: var(--color-border-grid);
      --color: var(--color-text-grid);
      //--row-hover-background-color: var(--color-background-grid-row-hover);
      --selection-color: transparent;
      overflow-y: scroll;
      border-top: none;
      .rdg-group-row {
        --header-background-color: #222;
      }

      .rdg-row {
        cursor: ${({ rowClickable }) => (rowClickable ? 'pointer' : 'default')};
        &:hover {
          background-color: var(--color-background-grid-row-hover);
          color: var(--color-text-grid-row-hover);
        }
      }

      .rdg-header-row {
        transition: height 0.3s;
        background-color: var(--color-background-gridColumn-header);
        font-weight: var(--font-weight-grid-header);
        font-size: var(--font-size-grid-header);
        .rdg-cell {
          display: flex;
          border-bottom-color: var(--color-border-gridColumn-header);
          border-right-color: var(--color-border-gridColumn-header);
          color: var(--color-text-gridColumn-header);
          .header-cell {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            cursor: pointer;
          }
        }
        .filter-input-wrapper {
          opacity: ${({ filterEnabled }) => (filterEnabled ? 1 : 0)};
          transition: opacity 0.3s;
        }
      }

      .rdg-cell {
        transition: height 0.3s;
        padding: 0 5px;
        .cell {
        }
        &:focus {
          outline: none;
        }
      }

      &.filtered-data-grid {
        .rdg-cell {
          line-height: 30px;
          padding: 0 5px;
        }
      }

      .rdg-header-sort-cell {
        display: flex;
        align-items: center;
      }
    }
  }
`;
