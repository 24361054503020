import cn from 'classnames';
import { get, isUndefined, isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import deprecated from '../../../util/validation/deprecated';
import required from '../../../util/validation/required';
import requireOneOfTheFollowing from '../../../util/validation/requireOneOfTheFollowing';
import { Edit } from '../../icons';
import { Translate, useTranslateObjects } from '../../localization';
import style from './style';

const Table = styled.table`
  ${style}
`;

export const SimpleGrid = ({
  columns,
  data,
  onRowEdit,
  isRowEditable = () => true,
  translate,
  keyPath,
  onRowClick,
  rowClickDisabled,
  testId: testIdDeprecated,
  'data-testid': dataTestId,
  activeRowId,
}) => {
  requireOneOfTheFollowing({ testId: testIdDeprecated, dataTestId });
  deprecated({ testId: testIdDeprecated }, { testId: 'Use data-testid' });

  const { translateObjects } = useTranslateObjects();

  const translatedData = React.useMemo(() => {
    const translatedColumns = translateObjects(data, translate);

    return translatedColumns || data || [];
  }, [data, translate, translateObjects]);

  const testId = React.useMemo(() => {
    return dataTestId || testIdDeprecated;
  }, [dataTestId, testIdDeprecated]);

  return (
    <Table
      className={cn('simple-grid')}
      $onRowClick={onRowClick}
      $rowClickDisabled={rowClickDisabled}
      data-testid={testId}
    >
      <thead>
        <tr data-testid={`${testId}-header-row`}>
          {columns.map(column => (
            <th key={column.nameStringId}>
              <Translate stringId={column.nameStringId} data-testid={`${testId}-heading-${column.id || column.path}`} />
            </th>
          ))}
          {onRowEdit && <th />}
        </tr>
      </thead>
      <tbody>
        {translatedData.map((row, idx) => {
          return (
            <tr
              key={row[keyPath || 'key']}
              data-testid={`${testId}-row-${idx}`}
              className={cn({ active: activeRowId && row[keyPath] === activeRowId })}
            >
              {columns.map(column => {
                return (
                  <td
                    key={column.nameStringId}
                    onClick={() => (rowClickDisabled ? undefined : onRowClick?.(row))}
                    data-testid={`${testId}-row-${idx}-${column.id || column.path}${
                      column.cellRenderer ? '' : '-value'
                    }`}
                  >
                    {column.cellRenderer
                      ? column.cellRenderer(row, `${testId}-row-${idx}-${column.id || column.path}-value`)
                      : get(row, column.path)}
                  </td>
                );
              })}

              {onRowEdit && (
                <td style={{ textAlign: 'center' }}>
                  {isRowEditable?.(row) && <Edit onClick={() => onRowEdit(row)} />}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
