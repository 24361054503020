import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import style from './style';
import Dropdown from './dropdown';
import Toggle from './toggle';

export const PLACEMENT = {
  BOTTOM_LEFT: 'bottomLeft',
  BOTTOM_CENTER: 'bottomCenter',
  BOTTOM_RIGHT: 'bottomRight',
  TOP_LEFT: 'topLeft',
  TOP_CENTER: 'topCenter',
  TOP_RIGHT: 'topRight',
};

export const TRIGGER = {
  CLICK: 'click',
  HOVER: 'hover',
  CONTEXT_MENU: 'contextMenu',
};

const Styled = styled.div`
  ${style}
`;

export const DropdownMenu = ({
  placement,
  children,
  className,
  menuItems,
  trigger = [TRIGGER.CLICK],
  onClick,
  activeId,
  toggleWhenTwo = false,
}) => {
  const shouldToggle = React.useMemo(() => toggleWhenTwo && menuItems.length === 2, [menuItems, toggleWhenTwo]);

  return (
    <Styled className={cn('cx-dropdown-menu', className)}>
      {shouldToggle ? (
        <Toggle menuItems={menuItems} onClick={onClick} activeId={activeId}>
          {children}
        </Toggle>
      ) : (
        <Dropdown
          menuItems={menuItems}
          onClick={onClick}
          activeId={activeId}
          trigger={trigger}
          placement={placement}
          className={className}
          toggleWhenTwo={toggleWhenTwo}
        >
          {children}
        </Dropdown>
      )}
    </Styled>
  );
};
