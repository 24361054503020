import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { StatusTimeline } from './StatusTimeline';
import style from './style';
import { Layout } from 'connex-cds';
import Material from '../../ticket-details/logistical-info/Material';
import UnloadingPoint from '../../ticket-details/logistical-info/UnloadingPoint';
import LoadingPoint from '../../ticket-details/logistical-info/LoadingPoint';

const { Column, Container } = Layout;

const Styled = styled(Column)`
  ${style}
`;

export const LogisticalInfo = ({ ticket }) => {
  return (
    <Styled className={cn('logistical-info')}>
      <div style={{ height: '85px' }}>
        <Container flex={1} style={{ padding: '5px' }}>
          <Material data={ticket} />
        </Container>
      </div>
      <LoadingPoint data={ticket} />
      <UnloadingPoint data={ticket} />
      <Column>
        <Container>
          <StatusTimeline />
        </Container>
      </Column>
    </Styled>
  );
};
