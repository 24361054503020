import { css } from 'styled-components';

export default css`
  .dropdown-content {
    display: flex;
    cursor: pointer;
    align-items: center;
    .icon {
      color: var(--color-text-page);
    }

    > :first-child {
      margin-right: 0.8em;
    }

    .label {
      .ant-typography {
        color: var(--color-text-page);
      }
    }
  }
`;
