import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';

import { Helper } from '../../typography';
import { Translate } from '../../localization';
import { useFormContext } from '../context-provider/FormProvider';

const StyledError = styled(Helper)`
  height: 16px;
  color: var(--color-text-validation);
`;

const StyledPlaceholder = styled.div`
  height: 16px;
`;

export const ValidationText = ({ path, name }) => {
  const formContext = useFormContext();
  const { errors, touched, isErrorsVisible } = formContext;

  const error = get(errors, path);

  return error && (get(touched, path) || isErrorsVisible) ? (
    <StyledError>
      <Translate stringId={error} data-testid={`validation-error-${name || path}-${error.type}`} />
    </StyledError>
  ) : (
    <StyledPlaceholder />
  );
};
