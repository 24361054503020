import { Form } from 'connex-cds';
import React from 'react';
import { SignatureActions } from './signature/SignatureActions';
import { SignatureContainer } from './signature/SignatureContainer';

export const SignatureSection = ({ ticketId }) => {
  const { values } = Form.useFormContext();

  const sigField = React.useMemo(() => {
    return values?.signature?.image;
  }, [values?.signature?.image]);

  return sigField ? <SignatureContainer /> : <SignatureActions ticketId={ticketId} />;
};
