import React from 'react';
import { FormattedDate } from 'react-intl';
import mutuallyExclusive from '../../../util/validation/mutuallyExclusive';
import { useLocalizationContext } from '../LocalizationProvider';
import toDateTime from '../util/toDateTime';

export const DayOfWeek = ({ date, long = false, short = false }) => {
  mutuallyExclusive({ long, short });

  const { timeZone } = useLocalizationContext();

  const dateTime = React.useMemo(() => toDateTime(date, timeZone), [date, timeZone]);

  return <FormattedDate value={dateTime} weekday={long ? 'long' : 'short'} />;
};
