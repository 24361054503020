import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Form, Layout } from 'connex-cds';
const { Row } = Layout;

const Styled = styled.div`
  margin-top: 20px;
  .row {
    margin-bottom: 5px;
    .left {
      flex: 1;
    }
  }
`;

export const ListSection = ({ requirementFulfilled }) => {
  const {
    Components: { AddButton, ListComponent, RequiredIndicator },
  } = Form.useFormContext();

  return (
    <Styled className={cn('list-section')}>
      <Row style={{ alignItems: 'flex-end' }}>
        <div className="left">
          <RequiredIndicator disabled={requirementFulfilled} />
        </div>
      </Row>
      <ListComponent simple />
    </Styled>
  );
};
