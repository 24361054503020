import cn from 'classnames';
import { Core, Drawer } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { SignatureComment } from '../../../signature-comment/SignatureComment';
import style from './style';

const Styled = styled.div`
  ${style}
`;
export const SignatureActions = ({ ticketId }) => {
  const { openDrawer } = Drawer.useDrawerContext();

  const handleClick = React.useCallback(() => {
    openDrawer({
      titleStringId: 'customerSignature',
      component: <SignatureComment ticketId={ticketId} />,
      width: '100%',
    });
  }, [openDrawer]);

  return (
    <Styled className={cn('signature-actions')}>
      <Core.Button
        stringId="provideSignature"
        onClick={handleClick}
        type="primary"
        data-testid="provide-signature-button"
      />
    </Styled>
  );
};
