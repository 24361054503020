import cn from 'classnames';
import { Core, Form, Icons, Localization, Typography } from 'connex-cds';
import { map, sortBy } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import style from '../style';

const { Timeline } = Core;
const { Translate } = Localization;

const Styled = styled.div`
  ${style}
`;

export const StatusTimeline = () => {
  const { values } = Form.useFormContext();

  const ticketEvents = map(values?.ticketEvents, (value, key) => ({ eventType: key, ...value }));
  const sortedEvents = sortBy(ticketEvents, ['eventDateTime']);

  const handleAction = React.useCallback(event => {
    console.log('handling', event);
  }, []);

  // We need to map our object to the requirements of the Timeline component.
  // Optionally, action can be added to zero or more items and, if present, will be rendered
  // to the right of the description.
  const items = React.useMemo(() => {
    return sortedEvents.map(event => ({
      isoDateString: event.eventDateTime,
      description: (
        <div>
          <div style={{ display: 'flex' }}>
            <div>
              <Translate stringId={event.eventType} />
            </div>
          </div>
        </div>
      ),
    }));
  }, [sortedEvents]);

  return (
    <Styled className={cn('simple-grid-story')}>
      <div style={{ marginTop: '-20px', marginLeft: '-10px', marginBottom: '10px' }}>
        <Typography.Subtitle>
          <Translate stringId="status" data-testid="statusLabel" /> (
          <Localization.TimeZone date={items[0].isoDateString} />)
        </Typography.Subtitle>
      </div>

      <Timeline items={items} noData={<div>No Data</div>} />
    </Styled>
  );
};
