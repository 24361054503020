import { css } from 'styled-components';

export default css`
  border: 1px solid var(--color-border-container);
  border-radius: var(--radius-container);
  background-color: var(--color-background-container);
  box-shadow: var(--boxShadow-container);
  padding: 10px;
  overflow: hidden;

  .summary {
    display: flex;
    cursor: pointer;
    align-items: center;
    .summary-section {
      flex: 1;
    }

    .toggle-section {
      .icon {
        transition: transform 0.3s;
        font-size: 18px;
      }

      &.open {
        .icon {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .body-section {
    transition: max-height 0.3s;
    max-height: 0;
    overflow: hidden;

    &.open {
      overflow: auto;
      max-height: 300px;
    }
  }
`;
