import { css } from 'styled-components';

const getColors = ($type, $disabled) => {
  const bgColor = $disabled
    ? `var(--color-background-chip-disabled)`
    : `var(--color-background-chip-${$type || 'info'})`;

  const fgColor = $disabled ? `var(--color-text-chip-disabled)` : `var(--color-text-chip-${$type || 'info'})`;

  return `
  background-color: ${bgColor};
  color: ${fgColor}`;
};

export default css`
  transition: all 0.3s;
  ${({ $type, $disabled }) => getColors($type, $disabled)};
  display: inline-block;
  border-radius: 11px;
  height: 22px;
  padding: 0 10px;
  text-transform: uppercase;
  width: fit-content;
`;
