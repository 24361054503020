import { css } from 'styled-components';

export default css`
  display: flex;
  height: 40px;
  width: 100%;
  margin: 10px;
  border-bottom: 1px solid #ccc;
  align-items: center;
  .left-side {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .right-side {
    display: flex;
    align-items: center;
    gap: 20px;
    //justify-content: flex-end;
  }
`;
