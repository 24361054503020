import React from 'react';
import { masterData } from '../../../../../../../api';

export const useMasterData = () => {
  const [busy, setBusy] = React.useState(false);
  const [data, setData] = React.useState(null);

  const getMasterData = React.useCallback(async typeId => {
    setBusy(true);
    const response = await masterData.getMasterData(typeId);
    setData(response);
    setBusy(false);
  }, []);

  return { getMasterData, busy, masterData: data };
};
