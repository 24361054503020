const handleMockResponse = response => {
  console.error('Using mock response');

  return new Promise(resolve => {
    setTimeout(() => {
      resolve(response);
    }, 1000);
  });
};

export const http = {
  get: response => handleMockResponse(response),
  post: response => handleMockResponse(response),
  patch: response => handleMockResponse(response),
};
