import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Form, Drawer } from 'connex-cds';

const Styled = styled.div``;

export const DriverAcceptance = () => {
  const {
    Components: { DriverAcceptanceReason, SubmitButton },
    values,
  } = Form.useFormContext();

  const { closeDrawer } = Drawer.useDrawerContext();

  const disabled = React.useMemo(() => {
    return !values?.driverAcceptanceReason;
  }, [values?.driverAcceptanceReason]);

  return (
    <Styled className={cn('driver-acceptance')}>
      <DriverAcceptanceReason />
      <SubmitButton
        stringId="submitAcceptance"
        data-testid="submit-driver-acceptance-button"
        onClick={closeDrawer}
        disabled={disabled}
      />
    </Styled>
  );
};
