import React from 'react';
import { Form } from 'connex-cds';
import styled from 'styled-components';

const Image = styled.img`
  width: 100%;
  position: absolute;
  top: 35px;
`;

export const SignatureImage = () => {
  const { values } = Form.useFormContext();

  const signature = React.useMemo(() => {
    return values?.signature?.image;
  }, [values?.signature?.image]);

  return signature ? <Image src={signature} /> : null;
};
