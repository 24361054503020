import React from 'react';
import { FormattedDate } from 'react-intl';
import { useLocalizationContext } from '../LocalizationProvider';
import toDateTime from '../util/toDateTime';

export const LongDate = ({ date, withDayOfWeek = false }) => {
  const { timeZone } = useLocalizationContext();

  const options = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    timeZone,
  };

  const dateTime = React.useMemo(() => toDateTime(date, timeZone), [date, timeZone]);

  if (!date) return null;

  return <FormattedDate value={dateTime} {...options} weekday={withDayOfWeek ? 'long' : undefined} />;
};
