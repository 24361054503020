import React, { useContext, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { find, values } from 'lodash';
import { consolidateMessages } from './util/consolidateMessages';
// should be passed in : import { messages as defaultGlobalMessages } from '../../i18n/messages-global';

export const SUPPORTED_LANGUAGES = [
  { id: 'de', label: 'languages.german' },
  { id: 'en-US', label: 'languages.english-us' },
  { id: 'uk', label: 'languages.ukrainian' },
];

export const LocalizationContext = React.createContext();

export const useLocalizationContext = () => {
  const context = useContext(LocalizationContext);
  if (!context) {
    throw new Error('useIntlContext cannot be called outside of the IntlContext context provider');
  }
  return context;
};

const cachedLanguageId = localStorage.getItem('language');

const supportedCachedLanguageId = () => {
  const known =
    cachedLanguageId && find(SUPPORTED_LANGUAGES, l => l.id.toLowerCase() === cachedLanguageId.toLowerCase());

  return known ? known.id : null;
};

const browserLanguageId = (navigator.languages && navigator.languages[0]) || navigator.language || 'en-US';

const supportedBrowserLanguageId = () => {
  const known = find(SUPPORTED_LANGUAGES, l => l.id.toLowerCase() === browserLanguageId.toLowerCase()) || 'en-US';
  return known ? known.id : null;
};

export const Localization = ({ initialLocale, children, messages, initialTimeZone }) => {
  const [editMode, setEditMode] = React.useState(false);
  const [userLocale, _setUserLocale] = useState(
    initialLocale || supportedCachedLanguageId() || supportedBrowserLanguageId()
  );
  const [timeZone, setTimeZone] = React.useState(initialTimeZone);
  const [consolidatedMessages, setConsolidatedMessages] = React.useState(consolidateMessages({ messages })[userLocale]);

  const setUserLocale = React.useCallback((locale, persist = true) => {
    if (locale && persist) {
      localStorage.setItem('language', locale);
    }
    _setUserLocale(locale);
  }, []);

  useEffect(() => {
    setConsolidatedMessages(consolidateMessages({ messages })[userLocale]);
  }, [messages, userLocale]);

  return (
    <LocalizationContext.Provider
      value={{ userLocale: userLocale || 'en-US', setUserLocale, editMode, setEditMode, timeZone, setTimeZone }}
    >
      <IntlProvider locale={userLocale || 'en-US'} messages={consolidatedMessages}>
        {children}
      </IntlProvider>
    </LocalizationContext.Provider>
  );
};
