import React from 'react';
import styled from 'styled-components';
import { Subtitle } from '../../typography';
import { Translate } from '../../localization';
import { RequiredIndicator } from './RequiredIndicator';

const Styled = styled.label`
  height: 22px;
  color: var(--form-element-text-color);
  display: inline-flex;
`;

export const Label = ({
  stringId,
  values,
  testId: testIdDeprecated,
  'data-testid': dataTestId,
  isRequired,
  disabled,
  children,
  additionalText = null,
}) => {
  const testId = React.useMemo(() => {
    return dataTestId || testIdDeprecated;
  }, [dataTestId, testIdDeprecated]);

  return (
    <Styled className="label">
      {children}
      <Subtitle>
        <Translate stringId={stringId} data-testid={`${testId}-label`} values={values} />
        {additionalText}
        <RequiredIndicator required={isRequired} disabled={disabled} testId={testId} />
      </Subtitle>
    </Styled>
  );
};
