import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const AccordionCard = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = React.useCallback(() => {
    setIsOpen(s => !s);
  }, []);

  const childrenArray = React.useMemo(() => {
    const arr = React.Children.toArray(children);
    if (arr?.length !== 2) throw new Error('AccordionCard requires exactly two children.');
    return arr;
  }, [children]);

  return (
    <Styled className={cn('accordion-card')}>
      <div className="summary" onClick={toggle}>
        <div className="summary-section">{childrenArray[0]}</div>
        <div className={cn('toggle-section', { open: isOpen })}>
          <i className="icon fa-solid fa-chevron-down"></i>
        </div>
      </div>
      <div className={cn('body-section', { open: isOpen })}>{childrenArray[1]}</div>
    </Styled>
  );
};
