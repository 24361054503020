import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import cn from 'classnames';
import { isObject } from 'lodash';

const Styled = styled.a`
  color: var(--color-text-link);
`;

export const Link = ({ to, onClick, testId, stringId, style, className, target = '_blank' }) => {
  const isExternal = to?.includes?.('://');

  const intl = useIntl();

  const linkText = React.useMemo(() => {
    const descriptor = { id: isObject(stringId) ? stringId.id : stringId };
    const values = isObject(stringId) ? stringId.values : undefined;

    return intl.formatMessage(descriptor, values);
  }, [intl, stringId]);

  const dataAttributes = { 'data-stringid': stringId, 'data-testid': testId };

  const handleClick = React.useCallback(
    e => {
      if (!isExternal) {
        e.preventDefault();
        onClick(to);
      }
    },
    [isExternal, onClick, to]
  );

  return (
    <Styled
      className={cn('link', className)}
      onClick={handleClick}
      {...dataAttributes}
      href={to}
      style={style}
      target={isExternal ? target : undefined}
    >
      {linkText}
    </Styled>
  );
};
