import cn from 'classnames';
import { Form } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { ListSection } from '../ListSection';
import { FormSection } from './FormSection';

const Styled = styled.div`
  .actions {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    justify-content: flex-end;
  }
`;

export const AddWater = () => {
  const {
    Components: { Water },
    isValid,
  } = Form.useFormContext();

  return (
    <Styled className={cn('water')}>
      <Water>
        <FormSection />
        <ListSection requirementFulfilled={isValid} />
      </Water>
    </Styled>
  );
};
