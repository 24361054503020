export const TYPE = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  DATE: 'date',
  ARRAY: 'array',
  OBJECT: 'object',
};

export const TYPE_HELPER = {
  string: { defaultValue: '', class: String },
  time: { defaultValue: '', class: String },
  password: { defaultValue: '', class: String },
  number: { defaultValue: 0, class: Number },
  boolean: { defaultValue: false, class: Boolean },
  date: { defaultValue: null, class: Date },
  array: { defaultValue: [], class: Array },
  object: { defaultValue: {}, class: Object },

};

export const coerceValue = ({ type, value }) => {

  if (type === TYPE.STRING || type === TYPE.ARRAY) {
    return value;
  }

  // if (type === TYPE.DATE) {
  //   // expect moment() instance.
  //   return value ? value.toISOString() : null;
  // }

  const coercedValue = TYPE_HELPER[type].class(value);

  return coercedValue;
};
