import { DateTime } from 'luxon';
import React from 'react';
import { Button } from '../../../../core';
import { useFormContext } from '../../../context-provider/FormProvider';
import { DEFAULT_MODE, MODES } from '../../Array';
import { useArrayContext } from '../../context/ArrayContext';

export const SaveButton = ({ onSave, suppressDisabledStyling }) => {
  const arrayContext = useArrayContext();
  const { showErrors, resetForm, dirty, isValid, values } = useFormContext();

  const [busy, setBusy] = React.useState(false);

  const {
    fieldConfig: { keyPath },
    onChange,
    value: existingValues,
    setMode,
    mode,
  } = arrayContext;

  const pushToParentForm = React.useCallback(
    values => {
      let newValues;

      if (mode === MODES.EDIT) {
        newValues = existingValues.map(existing => (values?.[keyPath] === existing[keyPath] ? values : existing));
      } else if (mode === MODES.CREATE) {
        newValues = [...existingValues, values];
      }

      onChange(newValues);

      setMode?.(DEFAULT_MODE);
    },
    [existingValues, keyPath, mode, onChange, setMode]
  );

  const handleSave = React.useCallback(() => {
    if (onSave) {
      const response = onSave(values);

      if (response instanceof Promise) {
        setBusy(true);
        response.finally(() => {
          pushToParentForm({ ...values, crn: values.crn || DateTime.now().toMillis() });
          resetForm({ reinitialize: true });
          setBusy(false);
          arrayContext.setActiveRowId(null);
        });
      } else {
        resetForm({ reinitialize: true });
        arrayContext.setActiveRowId(null);
      }
      // onSave overrides the default handleSave behavior;
      return;
    }
    resetForm({ reinitialize: true });
    arrayContext.setActiveRowId(null);

    pushToParentForm(values);
  }, [onSave, resetForm, arrayContext, pushToParentForm, values]);

  const disabled = React.useMemo(() => {
    if (suppressDisabledStyling) return false;

    return !dirty || !isValid || !mode;
  }, [dirty, isValid, mode, suppressDisabledStyling]);

  const handleClick = React.useCallback(() => {
    if (isValid) {
      handleSave();
    } else {
      showErrors?.();
    }
  }, [handleSave, isValid, showErrors]);

  return (
    <Button
      onClick={handleClick}
      type="primary"
      stringId={mode === MODES.CREATE ? 'add' : 'update'}
      disabled={disabled}
      data-testid="save-button"
      loading={busy}
      suppressDisabledStyling={suppressDisabledStyling}
    />
  );
};
