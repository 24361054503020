import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { CardSubtitle } from './CardSubtitle';
import { CardTitle } from './CardTitle';
import { CardBody } from './CardBody';

import style from './style';

const Styled = styled.div`
  ${style}
`;

export const Card = ({ status, titleStringId, subtitleStringId, bodyStringId, onClick }) => {
  return (
    <Styled className={cn('card', status, { clickable: !!onClick })} onClick={onClick}>
      <CardTitle titleStringId={titleStringId} />
      <CardSubtitle stringId={subtitleStringId} />
      <CardBody stringId={bodyStringId} />
    </Styled>
  );
};

Card.CardSubtitle = CardSubtitle;
Card.CardTitle = CardTitle;
Card.CardBody = CardBody;
