import { API, ConnexDesignSystem } from 'connex-cds';

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { auth } from './api';
import messages from './i18n';
import hostname from './util/hostname';
import Connex from './views/apps/connex/Connex';
import MobileTicket from './views/apps/mobile-ticket/MobileTicket';
import AuthenticatedLayout from './views/authenticated-layout/AuthenticatedLayout';
import AuthenticatedRoutes from './views/authenticated-routes/AuthenticatedRoutes';
import PublicLayout from './views/public-layout/PublicLayout';
import Root from './views/Root';
import Eula from './views/unauthenticated/Eula';
import Login from './views/unauthenticated/login/Login';
import Privacy from './views/unauthenticated/Privacy';
import { useApiCall } from 'connex-cds';

// TODO: Read this from somewhere.
API.configure({
  auth: {
    hostname: hostname(),
    apiKey: 'ZcHVAa6oQ31uY7xcA4SC47Rq64gaJqN73N7xsO70',
  },
  setup: {
    hostname: hostname(),
    apiKey: 'ZcHVAa6oQ31uY7xcA4SC47Rq64gaJqN73N7xsO70',
  },
  mobile_ticket: {
    hostname: hostname(),
    apiKey: 'ZcHVAa6oQ31uY7xcA4SC47Rq64gaJqN73N7xsO70',
  },
});

API.setSource('mobile-ticket');

const App = () => {
  const login = useApiCall(auth.login);

  return (
    <BrowserRouter>
      <ConnexDesignSystem onLogin={login} messages={messages} initialTimeZone="America/Chicago">
        <Routes>
          <Route path="/mt/*" element={<MobileTicket />} />
          <Route element={<PublicLayout />}>
            <Route path="eula" element={<Eula />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="login" element={<Login />} />
          </Route>
          <Route element={<AuthenticatedRoutes />}>
            <Route path="/" element={<Root />} exact />
            <Route path="/*" element={<AuthenticatedLayout />}>
              <Route path="connex/*" element={<Connex />} />
            </Route>
          </Route>
        </Routes>
      </ConnexDesignSystem>
    </BrowserRouter>
  );
};

export default App;
