import React, { useContext } from 'react';
import { cacheItem, clearCachedItem, getCachedItem } from '../../util/cache';

const AuthContext = React.createContext();

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuthContext cannot be rendered outside of the AuthContext context provider`);
  }
  return context;
};

export const AuthContextProvider = ({ onLogin, children }) => {
  const [isUserAuthenticated, setIsUserAuthenticated] = React.useState(!!getCachedItem('x-connex-id'));

  const setAuthentication = React.useCallback(loginResponse => {
    cacheItem('x-connex-id', loginResponse?.session?.connexId);
    setIsUserAuthenticated(true);
  }, []);

  const login = React.useCallback(
    async ({ credentials }) => {
      try {
        const response = await onLogin({ ...credentials });

        console.log('response', response);

        setAuthentication(response);
        return response;
      } catch (e) {
        console.log('e', e);
        console.log(e.stack);
      }
    },
    [onLogin, setAuthentication]
  );

  const logout = React.useCallback(() => {
    clearCachedItem('x-connex-id');
    setIsUserAuthenticated(false);
  }, []);

  return <AuthContext.Provider value={{ login, isUserAuthenticated, logout }}>{children}</AuthContext.Provider>;
};
