import { css } from 'styled-components';

export default css`
  background-color: var(--color-background-switch);
  color: var(--color-text-switch);
  --color-text-page: var(--color-text-switch);

  &.ant-switch-disabled {
    background-color: var(--color-background-switch-disabled);

    .ant-switch-inner {
      color: var(--color-text-switch-disabled);
    }
  }

  &.ant-switch-checked {
    background-color: var(--color-background-switch-checked);
    color: var(--color-text-switch-checked);
    --color-text-page: var(--color-text-switch-checked);

    &.ant-switch-disabled {
      background-color: var(--color-background-switch-checked-disabled);
      .ant-switch-inner {
        color: var(--color-text-switch-checked-disabled);
      }
    }
  }
`;
