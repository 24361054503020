import React from 'react';
import cn from 'classnames';
import { useGridContext } from './context/context';
import { useFocusRef } from './useFocusRef';
import {kebabCase} from "lodash";

const SORT_DIRECTION_INDICATOR = {
  ASC: (
    <svg viewBox="0 0 12 8" width="12" height="8" className="rdg-sort-arrow a1emyi9700-beta7" aria-hidden="true">
      <path d="M0 8 6 0 12 8" />
    </svg>
  ),

  DESC: (
    <svg viewBox="0 0 12 8" width="12" height="8" className="rdg-sort-arrow a1emyi9700-beta7" aria-hidden="true">
      <path d="M0 0 6 8 12 0" />
    </svg>
  ),
};

const filterComponents = ({ column, innerRef }) => ({
  select: props => {
    return (
      <select ref={innerRef} {...props}>
        <option value=""></option>
        {column?.filterComponent?.options.map(option => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>
    );
  },
});

const FilterComponent = p => {
  const { column, innerRef, ...props } = p;

  const Component = React.useMemo(() => {
    if (column.filterComponent) {
      if (typeof column.filterComponent === 'function') return column.filterComponent;

      const components = filterComponents({ column, innerRef });
      return components[column.filterComponent.type];
    }
    return ({ innerRef, ...p }) => <input {...p} ref={innerRef} />;
  }, [column, innerRef]);

  return <Component innerRef={innerRef} {...props} />;
};

const HeaderRenderer = ({ column, headerProps, filterable, headerClassName }) => {
  const { filters, setFilter, filterEnabled } = useGridContext();
  const { ref, tabIndex } = useFocusRef(headerProps.isCellSelected);

  return (
    <div
      className={cn('header-cell', column.headerClassName)}
      style={{ display: 'flex', flexDirection: 'column', padding: '0 5px', xjustifyContent: 'center', flex: '1' }}
    >
      <div
        onClick={e => {
          if (column.sortable !== false) headerProps.onSort(e.metaKey || e.ctrlKey);
        }}
        style={{ display: 'flex', alignItems: 'center', xpadding: '0 5px' }}
      >
        <span style={{ flex: '1' }}>{headerProps.column.name}</span>
        <span>
          {SORT_DIRECTION_INDICATOR[headerProps.sortDirection] || ''}
          {headerProps.priority || ''}
        </span>
      </div>

      {filterable !== false && filterEnabled ? (
        <div className="filter-input-wrapper" style={{ flex: '1' }}>
          <FilterComponent
            innerRef={ref}
            tabIndex={tabIndex}
            value={filters[column.key] || ''}
            style={{ height: '25px', borderRadius: '3px', border: '0', width: '100%', color: '#000' }}
            onChange={e => {
              setFilter(column.key, e.target.value);
            }}
            onKeyDown={event => {
              if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
                event.stopPropagation();
              }
            }}
            column={column}
            data-testid={`filter-${kebabCase(column.name?.toLowerCase?.())}`}
          />
        </div>
      ) : null}
    </div>
  );
};

export default HeaderRenderer;
