import { SUPPORTED_LANGUAGES } from '../LocalizationProvider';

import { flattenMessages } from './flattenMessages';

export const consolidateMessages = ({ messages }) => {
  // stub out object with a key for each supported locales
  const consolidatedMessages = SUPPORTED_LANGUAGES.reduce((acc, locale) => {
    acc[locale.id] = {};
    return acc;
  }, {});

  // Flatten messages
  Object.keys(messages).forEach(key => {
    if (consolidatedMessages[key]) {
      consolidatedMessages[key] = flattenMessages(messages[key]);
    }
  });

  return consolidatedMessages;
};
