import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import cn from 'classnames';
import { Typography, Layout } from 'connex-cds';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const FixedSidebar = ({ children }) => {
  const { entityRef } = useParams();
  return (
    <Styled className={cn('fixed-sidebar-wrapper')}>
      <div className="sidebar-content">
        <div style={{ opacity: '.15', textAlign: 'center', marginBottom: '10px' }}>
          <Typography.Helper>FixedSidebar.js</Typography.Helper>
        </div>
        <Link to={`/connex/${entityRef}/setup`}>Setup</Link>
      </div>
      <Layout.Column className="main-content">
        {children}
      </Layout.Column>
    </Styled>
  );
};

export default FixedSidebar;
