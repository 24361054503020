import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import style from '../../style';
import CommentForm from './CommentForm';

const Styled = styled.div`
  ${style}
`;
const TestCommentForm = () => {
  return (
    <Styled className={cn('test-form')}>
      <CommentForm />
    </Styled>
  );
};

export default TestCommentForm;
