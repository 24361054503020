import React from 'react';
import { SizeMe } from 'react-sizeme';
import { useColumnResize } from './useColumnResize';

const calculateHeaderWidths = columns => {
  const text = document.createElement('span');
  document.body.appendChild(text);

  text.style.fontWeight = 'var(--font-weight-grid-header)';
  text.style.fontSize = 'var(--font-size-grid-header)';
  text.style.top = '-5000px';
  text.style.height = 'auto';
  text.style.width = 'auto';
  text.style.position = 'absolute';
  text.style.whiteSpace = 'no-wrap';

  const widths = columns.reduce((acc, value) => {
    text.innerHTML = value.name;
    acc[value.key] = Math.ceil(text.clientWidth);
    return acc;
  }, {});

  document.body.removeChild(text);

  return widths;
};

const ColumnSizer = ({ children, autoSizeColumns, columns }) => {
  const { userDefinedWidths } = useColumnResize();

  if (!autoSizeColumns) return children;

  const contentWidths = React.useMemo(() => {
    return calculateHeaderWidths(columns);
  }, [columns]);

  return (
    <SizeMe monitorWidth refreshRate={100}>
      {({ size }) => {
        console.log('size', size);
        // Determine ideal width for each column that can accommodate header content.
        const recommendedWidths = columns.reduce((acc, column) => {
          acc[column.key] = Math.max(column.width || 0, contentWidths[column.key] + 20);
          return acc;
        }, {});

        // Get the cumulative width and count of columns that have their widths specified in the config.
        const configWidths = columns.reduce(
          (acc, col) => {
            if (col.width) {
              acc.width += Math.max(col.width || 0, recommendedWidths[col.key]);
              acc.columnCount++;
            }
            return acc;
          },
          { width: 0, columnCount: 0 }
        );

        //Spread the remaining width evenly among the remaining columns.
        const autoSizedColumns = columns.map(column => ({
          width: (size.width - 2 - configWidths.width) / (columns.length - configWidths.columnCount),
          ...column,
        }));

        // Write the widths to the column config.
        const autoSizedWithUserDefinedWidthsApplied = autoSizedColumns.map(column => {
          return {
            ...column,
            width: userDefinedWidths[column.key] || Math.max(column.width || 0, contentWidths[column.key] + 20),
          };
        });

        // Replace the "columns" prop with our modified version.
        return React.Children.map(children, child => {
          return React.cloneElement(child, { ...child.props, columns: autoSizedWithUserDefinedWidthsApplied });
        });
      }}
    </SizeMe>
  );
};

export default ColumnSizer;
