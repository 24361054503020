import { Form } from 'connex-cds';

export const formConfig = {
  fields: [
    {
      path: 'credentials.username',
      name: 'username',
      labelStringId: 'username',
      dataType: Form.FIELD_TYPES.string,
      required: { message: 'required' },
      showRequiredIndicator: false,
      initialFocus: true,
      testId: 'login-username',
    },
    {
      path: 'credentials.password',
      name: 'password',
      labelStringId: 'password',
      dataType: Form.FIELD_TYPES.string,
      required: { message: 'required' },
      showRequiredIndicator: false,
      password: true,
      testId: 'login-password',
    },
  ],
};
