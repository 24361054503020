import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Typography, Theme, Localization } from 'connex-cds';

const { LocaleSwitcher } = Localization;
const { ThemeSwitcher } = Theme;
const { Helper } = Typography;

const Styled = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 10px;
`;

const Footer = () => {
  return (
    <Styled className={cn('footer')}>
      <div style={{ display: 'flex', gap: '20px', flex: '1' }}>
        <LocaleSwitcher />
        <ThemeSwitcher />
      </div>

      <div>
        <Helper>Version: 1.0.0</Helper>
      </div>
    </Styled>
  );
};

export default Footer;
