import cn from 'classnames';
import { Form, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
`;

export const TimeStamp = () => {
  const { values } = Form.useFormContext();

  const date = React.useMemo(() => {
    return values?.signature?.timestamp;
  }, [values?.signature?.timestamp]);

  return (
    <Styled className={cn('time-stamp')}>
      <Typography.Subtitle>
        <Localization.ShortDateTime date={date} withDayOfWeek />
      </Typography.Subtitle>
    </Styled>
  );
};
