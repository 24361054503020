import cn from 'classnames';
import { Localization, Layout, Form } from 'connex-cds';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import messages from '../../../../i18n';
import { Comments } from './comments/Comments';
import { Header } from './header/Header';
import { LogisticalInfo } from './logistical-info/LogisticalInfo';
import { ProductsAndServices } from './products-and-services/ProductsAndServices';
import { SignatureSection } from './signature-section/SignatureSection';
import style from './style';

const { Column, Row } = Layout;

const Styled = styled(Column)`
  ${style}
`;

export const Acceptance = ({ ticket }) => {
  const { userLocale, timeZone } = Localization.useLocalizationContext();
  const { validateForm, isValid } = Form.useFormContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isValid) {
      validateForm();
    } else {
      navigate('..');
    }
  }, [isValid, navigate, validateForm]);

  return (
    <Localization.Localization messages={messages} initialLocale={userLocale} initialTimeZone={timeZone}>
      <Styled className={cn('acceptance')}>
        <Column>
          <Row>
            <Header ticket={ticket} />
          </Row>
          <Row flex={1}>
            <Column flex={1}>
              <LogisticalInfo ticket={ticket} />
            </Column>
            <Column flex={2}>
              <Row flex={2}>
                <Column>
                  <ProductsAndServices />
                </Column>
                <Column>
                  <Comments />
                </Column>
              </Row>
              <Row flex={1}>
                <SignatureSection ticketId={ticket.id} />
              </Row>
            </Column>
          </Row>
        </Column>
      </Styled>
    </Localization.Localization>
  );
};
