import { Input as AntInput } from 'antd';
import React from 'react';
import styled from 'styled-components';
import style from './style';

const { TextArea } = AntInput;

const StyledAntInput = styled(AntInput)`
  ${style}
`;

const StyledAntTextArea = styled(TextArea)`
  ${style}
`;

export const Input = React.forwardRef(({ onChange, testId, multiline, ...props }, ref) => {
  const dataTestId = { 'data-testid': testId };
  const handleChange = React.useCallback(
    e => {
      onChange(e?.target?.value);
    },
    [onChange]
  );

  return multiline ? (
    <StyledAntTextArea ref={ref} rows={4} {...props} onChange={handleChange} {...dataTestId} $multiline={multiline} />
  ) : (
    <StyledAntInput ref={ref} {...props} onChange={handleChange} {...dataTestId} />
  );
});
