import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const HeaderFooter = ({ children }) => {
  const childrenArray = React.useMemo(() => {
    return React.Children.toArray(children);
  }, [children]);

  return (
    <Styled className={cn('header-footer')}>
      <div className="app-header">{childrenArray[0]}</div>
      <div className="app-content">{childrenArray[1]}</div>
      <div className="app-footer">{childrenArray[2]}</div>
    </Styled>
  );
};

export const Header = ({ children }) => children;
