import { Drawer as AntDrawer } from 'antd';
import React, { useContext } from 'react';
import { Translate } from '../localization';

const DrawerContext = React.createContext();

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error(`useDrawer cannot be rendered outside of the Drawer context provider`);
  }
  return context;
};

const Drawer = () => {
  const { visible, title, titleStringId, width, placement, onClose, component, closeDrawer, closable } = useDrawerContext();

  const handleClose = React.useCallback(() => {
    closeDrawer();
    onClose?.();
  }, [closeDrawer, onClose]);

  return (
    <AntDrawer
      visible={visible}
      title={title}
      placement={placement}
      onClose={handleClose}
      width={width}
      closable={closable}
      destroyOnClose
      getContainer={false}
    >
      {component}
    </AntDrawer>
  );
};

export const DrawerProvider = ({ children }) => {
  const [visible, setVisible] = React.useState(false);
  const [titleStringId, setTitleStringId] = React.useState(null);
  const [placement, setPlacement] = React.useState('right');
  const [onClose, setOnClose] = React.useState(null);
  const [component, setComponent] = React.useState(null);
  const [width, setWidth] = React.useState(null);
  const [closable, setCloseable] = React.useState(true);

  const title = React.useMemo(() => {
    if (!titleStringId) return undefined;

    if (titleStringId.stringId && titleStringId.values) {
      return <Translate stringId={titleStringId.stringId} values={titleStringId.values} />;
    }

    console.log('title', titleStringId);

    return <Translate stringId={titleStringId} />;
  }, [titleStringId]);

  const openDrawer = React.useCallback(
    ({ titleStringId, onClose, placement = 'right', component, width = 500, closable = true }) => {
      setTitleStringId(titleStringId);
      setOnClose(() => onClose);
      setPlacement(placement);
      setVisible(true);
      setComponent(() => component);
      setWidth(width);
      setCloseable(closable);
    },
    []
  );

  const closeDrawer = React.useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <DrawerContext.Provider
      value={{ visible, title, titleStringId, placement, onClose, component, openDrawer, closeDrawer, width, closable }}
    >
      {children}
      <Drawer />
    </DrawerContext.Provider>
  );
};
