import { css } from 'styled-components';

export default css`
  .comment {
    textarea {
      width: 85%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .signature-outer {
    width: 95%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20%;
    .signature-inner {
      border: 1px solid #ccc;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`;
