import cn from 'classnames';
import React from 'react';
import { Form, Layout } from 'connex-cds';
import styled from 'styled-components';
import style from '../../style';

const Styled = styled.div`
  ${style}
`;

const { Column, Row, Container } = Layout;

export const FormSection = ({ className }) => {
  const {
    Components: { AddedBy, Product, Quantity, ConcreteOnTruck, Time, CancelButton, SaveButton, DeleteButton },
  } = Form.useFormContext();

  return (
    <Styled className={cn('form-section', className)}>
      <Container flex={1}>
        <Row stretchContent>
          <AddedBy
            options={[
              { id: 'driver', label: 'Driver' },
              { id: 'customer', label: 'Customer' },
            ]}
          />
        </Row>
        <Row stretchContent>
          <Product />
          <Quantity />
        </Row>
        <Row stretchContent>
          <ConcreteOnTruck />
          <Time />
        </Row>
        <Row>
          <Column>
            <DeleteButton />
          </Column>
          <Column>
            <Row style={{ justifyContent: 'right' }}>
              <CancelButton />
              <SaveButton />
            </Row>
          </Column>
        </Row>
      </Container>
    </Styled>
  );
};
