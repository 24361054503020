import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Switch } from '../../core/switch/Switch';
import { useLocalizationContext } from '../LocalizationProvider';

const Styled = styled.div`
  display: flex;
  padding-top: 10px;
  gap: 10px;
  align-items: center;
`;

export const EditModeSwitcher = ({ className }) => {
  const { editMode, setEditMode } = useLocalizationContext();

  const handleChange = React.useCallback(
    value => {
      setEditMode(value);
    },
    [setEditMode]
  );

  return (
    <Styled className={cn('edit-mode-switcher', className)}>
      <div>
        Edit Mode
      </div>
      <Switch checkedStringId="on" uncheckedStringId="off" onChange={handleChange} value={editMode} />
    </Styled>
  );
};
