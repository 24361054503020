import cn from 'classnames';
import { Typography, Localization } from 'connex-cds';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import style from '../setup-org/style';
import config from './config';
const { Translate } = Localization;

const Styled = styled.div`
  ${style}
`;

export const Setup = props => {
  const navigate = useNavigate();

  const handleClick = React.useCallback(
    ref => {
      navigate(ref);
    },
    [navigate]
  );

  return (
    <Styled className={cn('setup')}>
      <Typography.H2>Setup</Typography.H2>
      <div className="layout-grid">
        {Object.entries(config).map(([key, value]) => {
          return (
            <div onClick={() => handleClick(key)}>
              {value?.icon}
              <Translate stringId={key} />
            </div>
          );
        })}
      </div>
    </Styled>
  );
};
