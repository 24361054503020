import { css } from 'styled-components';

export default css`
  &&& {
    .ant-picker {
      transition: all 0.3s;
      border-radius: 4px;
      font-size: 14px;
      height: 35px;
      font-weight: 400;
      line-height: 16.41px;

      background-color: var(--color-background-input);
      color: var(--color-text-input);
      border-color: var(--color-border-input);

      &:focus {
        border-color: var(--color-border-input-focus);
      }

      &.ant-picker-disabled {
        background-color: var(--color-background-input-disabled);
        color: var(--color-text-input-disabled);
        border-color: var(--color-border-input-disabled);
        .ant-picker-input {
          .ant-picker-suffix {
            color: var(--color-border-input-disabled);
          }
        }
      }

      .ant-picker-input {
        .ant-picker-suffix {
          transition: all 0.3s;
          color: var(--color-text-input);
        }
      }
    }
  }
`;
