import { css } from 'styled-components';

export default css`
  padding: 0 10px;
  .header {
    display: flex;
    height: 40px;
    margin: 10px;
    border-bottom: 1px solid #ccc;
    align-items: center;
    .left-side {
      flex: 1;
      display: flex;
      align-items: center;
    }
    .right-side {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
  .footer {
    height: 40px;
    display: flex;
    align-items: center;
  }
  .left-column {
    width: 300px;
    .left {
      height: 100%;
    }
  }
  .right-column {
    gap: 20px;
    .right {
    }
  }
  .table-section {
    margin: 0 10px;
    table {
      width: 100%;
    }
    th {
      text-align: left;
    }
  }
`;
