import React, { useEffect, useState } from 'react';
import { useGridContext } from '../../context/context';
import ExportToCsv from '../../util/ExportToCsv';

const ExportButton = () => {
  const { rows, columns } = useGridContext();
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(rows);
  }, [rows]);

  return <ExportToCsv columns={columns} exportFileName={`connex-export`} rows={rows} />;
};

export default ExportButton;
