import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';

const SIZE = {
  large: 365,
  small: 350,
};

export const useQrCode = ({ data, size = 'large' }) => {
  const [qr, setQr] = useState();

  useEffect(() => {
    QRCode.toDataURL(data, { width: SIZE[size] }, (err, url) => {
      setQr(<img className="barcode" src={url} />);
    });
  }, [data, size]);
  return qr;
};
