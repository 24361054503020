import React from 'react';
import { Button } from '../../../../core';
import { useFormContext } from '../../../context-provider/FormProvider';
import { DEFAULT_MODE, MODES } from '../../Array';
import { useArrayContext } from '../../context/ArrayContext';

export const ResetButton = ({ onReset, onClick, hideWhenDisabled = true, ...props }) => {
  const { mode } = useArrayContext();
  const { resetForm, dirty } = useFormContext();

  const handleReset = React.useCallback(
    e => {
      resetForm();
      onReset?.();
      onClick?.(e);
    },
    [onClick, onReset, resetForm]
  );

  const disabled = React.useMemo(() => {
    return !dirty || mode !== MODES.EDIT;
  }, [dirty, mode]);

  if (hideWhenDisabled && disabled) return null;

  return <Button {...props} onClick={handleReset} stringId="reset" data-testid="reset-button" disabled={disabled} />;
};
