import { API } from '../api';
import queryString from 'query-string';
import { getCachedItem } from '../../util/cache';
// import { checkVersion } from './views/order/version/version';

const handleFetch = async ({ apiName, method, path = '', data = {}, queryParams = {} }) => {
  const query = queryString.stringify(queryParams);

  const apiConfig = API.getConfig(apiName);
  const source = API.getSource();

  const options = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': apiConfig.apiKey,
      'x-connex-id': getCachedItem('x-connex-id'),
    },
    body: method === 'GET' ? undefined : JSON.stringify({ source, ...data }),
  };
  const response = await fetch(`${apiConfig.hostname}${path}${query ? `?${query}` : ''}`, options);
  // checkVersion(response.headers.get('x-connex-version'));

  if (response.ok) {
    return response.json();
  }

  const error = await response.json();

  API.handleError(error?.issues?.map?.(issue => issue.message)?.join?.(', ') || 'An error has occurred');
  return { error };
};

export const http = apiName => ({
  post: args => handleFetch({ apiName, method: 'POST', ...args }),
  patch: args => handleFetch({ apiName, method: 'PATCH', ...args }),
  get: args => handleFetch({ apiName, method: 'GET', ...args }),
});
