import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import FixedSidebar from './fixed-sidebar/FixedSidebar';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const Sidebar = ({ children }) => {
  return (
    <Styled className={cn('sidebar')}>
      <FixedSidebar>{children}</FixedSidebar>
    </Styled>
  );
};
