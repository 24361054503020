import cn from 'classnames';
import { Form, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;
export const TicketNumber = () => {
  const { values } = Form.useFormContext();

  const ticketId = React.useMemo(() => {
    return values?.signature.ticketId;
  }, [values?.signature.ticketId]);

  return (
    <Styled className={cn('ticket-number')}>
      <Typography.Subtitle>Ticket #{ticketId}</Typography.Subtitle>
    </Styled>
  );
};
