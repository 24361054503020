import { camelCase } from 'lodash';
import React from 'react';
import { Button } from '../../../../core';
import { useFormContext } from '../../../context-provider/FormProvider';
import { MODES } from '../../Array';
import { useArrayContext } from '../../context/ArrayContext';

export const AddButton = props => {
  const arrayContext = useArrayContext();
  const formContext = useFormContext();

  const { setMode } = arrayContext;

  const handleAdd = React.useCallback(
    e => {
      setMode?.(MODES.CREATE);
      formContext.resetForm({ reinitialize: true });
      props?.onClick?.(e);
    },
    [formContext, props, setMode]
  );

  const stringId = React.useMemo(() => {
    return props.stringId ? props.stringId : camelCase(`add_${arrayContext?.fieldConfig?.labelStringId || 'item'}`);
  }, [arrayContext?.fieldConfig?.labelStringId, props.stringId]);

  return <Button {...props} stringId={stringId} type="primary" onClick={handleAdd} data-testid="add-button" />;
};
