import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from '../../style';
import { Core, Localization } from 'connex-cds';

const { SimpleGrid } = Core;
const { Uom } = Localization;

const Styled = styled.div`
  ${style}
`;
const columns = [
  { path: 'product', nameStringId: 'product' },
  { path: 'quantity', nameStringId: 'quantity', cellRenderer: row => <Uom uom={row.quantity} /> },
];
const data = [
  {
    product: 'C25/30 20 CIIIA+ S3',
    quantity: { value: 5, uomCode: 'MTQ' },
  },
  {
    product: 'Plasticizer Added',
    quantity: { value: 0, uomCode: 'LTR' },
  },
  {
    product: 'Retarder Added',
    quantity: { value: 0, uomCode: 'LTR' },
  },
  /*{
        product: 'Water Added',
        quantity: null,
    },
    {
        product: 'Unloading Method',
    },*/
];

const ProductList = props => {
  return (
    <Styled className={cn('the-form')}>
      <SimpleGrid columns={columns} data={data} data-testid="productGrid" />
    </Styled>
  );
};

export default ProductList;
