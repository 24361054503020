import cn from 'classnames';
import { Form } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { FormSection } from './FormSection';
import { ListSection } from '../ListSection';

const Styled = styled.div`
  .actions {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    justify-content: flex-end;
  }
`;

export const AddProducts = props => {
  const {
    Components: { MaterialService },
  } = Form.useFormContext();

  return (
    <Styled className={cn('water')}>
      <MaterialService>
        <FormSection />
        <ListSection />
      </MaterialService>
    </Styled>
  );
};
