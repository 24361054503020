import React from 'react';

export const SEVERITY = {
  INFO: {
    type: 'info',
    icon: (
      <span>
        <i class="fa-solid fa-circle-info" />
      </span>
    ),
  },
  SUCCESS: {
    type: 'success',
    icon: (
      <span>
        <i className="fa-solid fa-circle-check" />
      </span>
    ),
  },
  WARNING: {
    type: 'warning',
    icon: (
      <span>
        <i className="fa-solid fa-triangle-exclamation" />
      </span>
    ),
  },
  ERROR: {
    type: 'error',
    icon: (
      <span>
        <i className="fa-solid fa-circle-exclamation" />
      </span>
    ),
  },
};
