import React from 'react';
import { Button } from '../../../../core';
import { useFormContext } from '../../../context-provider/FormProvider';
import { DEFAULT_MODE, MODES } from '../../Array';
import { useArrayContext } from '../../context/ArrayContext';

export const DeleteButton = ({ hideWhenDisabled = true, onDelete }) => {
  const arrayContext = useArrayContext();
  const formContext = useFormContext();

  const {
    fieldConfig: { keyPath },
    onChange,
    value,
    setMode,
    mode,
    setActiveRowId,
  } = arrayContext;

  const handleDelete = React.useCallback(() => {
    const values = formContext.values;

    const newValues = value.filter(existing => values?.[keyPath] !== existing[keyPath]);

    onChange(newValues);

    setMode?.(DEFAULT_MODE);

    formContext.resetForm({ reinitialize: true });

    setActiveRowId(null);

    onDelete?.();
  }, [formContext, keyPath, onChange, onDelete, setActiveRowId, setMode, value]);

  const disabled = React.useMemo(() => {
    return mode !== MODES.EDIT;
  }, [mode]);

  if (hideWhenDisabled && disabled) return null;

  return (
    <Button
      onClick={handleDelete}
      stringId="delete"
      type="primary"
      danger
      disabled={disabled}
      data-testid="delete-button"
    />
  );
};
