import React from 'react';
import { Button } from '../../../../core';
import { useFormContext } from '../../../context-provider/FormProvider';
import { DEFAULT_MODE, MODES } from '../../Array';
import { useArrayContext } from '../../context/ArrayContext';

/*
The default behavior of this button is to be disabled when creating a new item until there's been a change.
This makes sense When the ListComponent and Array form are on the same screen....not so much when the array form
is in a drawer.  Setting the 'enabled' prop allows the dev to override the default behavior.
 */

export const CancelButton = ({ enabled, onClick, onCancel, ...props }) => {
  const { setMode, mode, setActiveRowId } = useArrayContext();
  const { resetForm, dirty } = useFormContext();

  const handleCancel = React.useCallback(
    e => {
      resetForm({ reinitialize: true });
      setMode?.(DEFAULT_MODE);
      setActiveRowId(null);
      onCancel?.();
      onClick?.(e);
    },
    [onCancel, onClick, resetForm, setActiveRowId, setMode]
  );

  const disabled = React.useMemo(() => {
    if (enabled) return false;
    return (mode === MODES.CREATE && !dirty) || !mode;
  }, [dirty, enabled, mode]);

  return <Button {...props} onClick={handleCancel} stringId="cancel" data-testid="cancel-button" disabled={disabled} />;
};
