import { css } from 'styled-components';

export default css`
  &&& {
    .ant-checkbox {
      .ant-checkbox-inner {
        background-color: var(--color-background-checkbox);
        border-color: var(--color-border-checkbox);
      }

      &.ant-checkbox-disabled {
        .ant-checkbox-inner {
          background-color: var(--color-background-checkbox-disabled);
          border-color: var(--color-border-checkbox-disabled) !important;
        }
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: var(--color-background-checkbox-checked);
          border-color: var(--color-border-checkbox-checked);
          &:after {
            border-color: var(--color-icon-checkbox);
          }
        }
        &.ant-checkbox-disabled {
          .ant-checkbox-inner {
            background-color: var(--color-background-checkbox-checked-disabled);
            border-color: var(--color-border-checkbox-checked-disabled) !important;
            &:after {
              border-color: var(--color-icon-checkbox-disabled);
            }
          }
        }
      }
    }
  }
`;
