import React from 'react';
import { ShortDate } from './ShortDate';
import { Time } from './Time';

export const ShortDateTime = ({ date, hideTimeZone = false, withDayOfWeek = false }) => {
  return (
    <>
      <ShortDate date={date} withDayOfWeek={withDayOfWeek} /> <Time date={date} hideTimeZone={hideTimeZone} />
    </>
  );
};
